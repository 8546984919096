import React, { useState } from "react";
import "../assets/css/share.css";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  //   EmailShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TelegramShareButton,
} from "react-share";
import {
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  //   EmailIcon,
  LinkedinIcon,
  RedditIcon,
  TelegramIcon,
} from "react-share";

const Share = ({ product, onClose }) => {
  const [copied, setCopied] = useState(false);

  if (!product) return null; // Ensure hooks are always called before returns

  const productUrl = window.location.origin + "/shopdetail/" + product;

  const handleCopyLink = () => {
    if (!productUrl) return;
    navigator.clipboard.writeText(productUrl);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  // Check if it's a mobile device or tablet using window size
  const isMobile = window.innerWidth <= 768; // Adjust this threshold if needed

  // Function to trigger native share dialog on mobile
  const handleShare = async () => {
    if (isMobile && navigator.share) {
      try {
        await navigator.share({
          title: product.name,
          url: productUrl,
        });
      } catch (error) {
        console.log("Error sharing: ", error);
      }
    } else {
      console.log("Native share not supported or device is not mobile/tablet");
    }
  };

  // If it's a mobile device, trigger native share directly
  if (isMobile) {
    handleShare(); // Call share directly when rendered
    onClose(); // Close the modal immediately after triggering the native share
    return null; // No need to display anything else
  }

  // For desktop or non-mobile devices, show the custom modal with social media buttons
  return (
    <div className="share-modal">
      {/* Close button */}
      <button
        className="close-btn"
        onClick={onClose}
        aria-label="Close share modal"
      >
        &times;
      </button>
      <h4>Share this product:</h4>
      <div className="share-buttons">
        <FacebookShareButton url={productUrl} aria-label="Share on Facebook">
          <FacebookIcon size={40} round />
        </FacebookShareButton>

        <TwitterShareButton
          url={productUrl}
          title={product.name}
          aria-label="Share on Twitter"
        >
          <TwitterIcon size={40} round />
        </TwitterShareButton>

        <WhatsappShareButton
          url={productUrl}
          title={product.name}
          aria-label="Share on WhatsApp"
        >
          <WhatsappIcon size={40} round />
        </WhatsappShareButton>

        <LinkedinShareButton url={productUrl} aria-label="Share on LinkedIn">
          <LinkedinIcon size={40} round />
        </LinkedinShareButton>

        <RedditShareButton
          url={productUrl}
          title={product.name}
          aria-label="Share on Reddit"
        >
          <RedditIcon size={40} round />
        </RedditShareButton>

        <TelegramShareButton
          url={productUrl}
          title={product.name}
          aria-label="Share on Telegram"
        >
          <TelegramIcon size={40} round />
        </TelegramShareButton>

        <button
          className="copy-btn"
          onClick={handleCopyLink}
          aria-label="Copy product link"
        >
          <span role="alert">{copied ? "Copied!" : "Copy Link"}</span>
        </button>
      </div>
    </div>
  );
};

export default Share;

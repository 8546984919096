import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";

const MessageSkeleton = ({ height, length, lg = "3", xl }) => {
  return (
    <>
      {" "}
      {[...Array(3)].map((_, index) => (
        <Grid container spacing={2} key={index} className="mb-4">
          <Grid item xs={4}>
            <Skeleton variant="text" width="100%" height={50} />
            
          </Grid>
          <Grid item xs={4}>
            <Skeleton variant="text" width="100%" height={50} />
            
          </Grid>
          <Grid item xs={4}>
            <Skeleton variant="text" width="100%" height={50} />
            
          </Grid>
        </Grid>
      ))}
    </>
  );
};

export default MessageSkeleton;

import React, { useState, useEffect } from "react";
import "../assets/css/PriceRangeSlider.css";
import Swal from "sweetalert2";

const Filtering = ({ data, onFilter, initialSearchText = "" }) => {
  const [searchText, setSearchText] = useState(initialSearchText);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(50000); // Default max value
  const [sliderMin, setSliderMin] = useState(0);
  const [sliderMax, setSliderMax] = useState(50000); // Default slider max value

  // Calculate the minimum and maximum price from the data
  useEffect(() => {
    if (data && data.length > 0) {
      const prices = data.map((item) => item.price ?? 0); // Treat undefined prices as 0
      const minDataPrice = Math.min(...prices);
      const maxDataPrice = Math.max(...prices);

      if (minDataPrice !== sliderMin || maxDataPrice !== sliderMax) {
        setSliderMin(minDataPrice);
        setSliderMax(maxDataPrice);

        if (minPrice < minDataPrice || maxPrice > maxDataPrice) {
          setMinPrice(minDataPrice);
          setMaxPrice(maxDataPrice);
        }
      }
    }
  }, [data, sliderMin, sliderMax, minPrice, maxPrice]);

  // Handle price validation and filtering
  const handleRangeInput = (e) => {
    const { className, value } = e.target;
    const newValue = parseFloat(value);

    if (className.includes("min-range")) {
      if (newValue >= sliderMin && newValue <= maxPrice) {
        setMinPrice(newValue);
      } else {
        Swal.fire({
          icon: "error",
          title: "Invalid Input",
          text: `Minimum price must be between ${sliderMin} and ${maxPrice}`,
          confirmButtonColor: "#F6921E",
          confirmButtonText: "OK",
        });
      }
    } else {
      if (newValue <= sliderMax && newValue >= minPrice) {
        setMaxPrice(newValue);
      } else {
        Swal.fire({
          icon: "error",
          title: "Invalid Input",
          text: `Maximum price must be between ${minPrice} and ${sliderMax}`,
          confirmButtonColor: "#F6921E",
          confirmButtonText: "OK",
        });
      }
    }
  };

  // Apply filter whenever the search text, minPrice, or maxPrice changes
  useEffect(() => {
    if (onFilter) {
      if (minPrice === 0 && maxPrice === 0) {
        // If both minPrice and maxPrice are 0, filter for items with undefined prices
        const filteredData = data.filter((item) => item.price === null);
        onFilter(searchText, filteredData);
      } else {
        onFilter(searchText, minPrice, maxPrice);
      }
    }
  }, [searchText, minPrice, maxPrice, data, onFilter]);

  return (
    <div className="automobile-filters">
      <div className="form-row">
        <div className="form-group">
          <div className="row">
            <div className="col-md-12 mb-3">
              <label htmlFor="search">Search</label>
              <input
                type="text"
                id="search"
                className="form-control"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                placeholder="Search here"
                aria-label="Search input"
              />
            </div>
            <div className="col-md-12 mb-3">
              <label htmlFor="price">Price Range</label>
              <div className="price-input-container">
                <div className="slider-container">
                  <div
                    className="price-slider"
                    style={{
                      left: `${((minPrice - sliderMin) / (sliderMax - sliderMin)) * 100}%`,
                      right: `${100 - ((maxPrice - sliderMin) / (sliderMax - sliderMin)) * 100}%`,
                    }}
                  />
                </div>
                <div className="range-input mb-4">
                  <input
                    type="range"
                    className="min-range"
                    min={sliderMin}
                    max={sliderMax}
                    step="0.01"
                    value={minPrice}
                    onChange={handleRangeInput}
                    aria-label="Minimum price range"
                  />
                  <input
                    type="range"
                    className="max-range"
                    min={sliderMin}
                    max={sliderMax}
                    step="0.01"
                    value={maxPrice}
                    onChange={handleRangeInput}
                    aria-label="Maximum price range"
                  />
                </div>
                <div className="price-input">
                  <div className="price-field">
                    <span>Minimum Price</span>
                    <input
                      type="number"
                      className="min-input"
                      value={minPrice}
                      onChange={(e) => {
                        const newValue = parseFloat(e.target.value);
                        if (newValue >= sliderMin && newValue <= maxPrice) {
                          setMinPrice(newValue);
                        } else {
                          Swal.fire({
                            icon: "error",
                            title: "Invalid Input",
                            text: `Minimum price must be between ${sliderMin} and ${maxPrice}`,
                            confirmButtonColor: "#F6921E",
                            confirmButtonText: "OK",
                          });
                        }
                      }}
                      aria-label="Minimum price input"
                    />
                  </div>
                  <div className="price-field">
                    <span>Maximum Price</span>
                    <input
                      type="number"
                      className="max-input"
                      value={maxPrice}
                      onChange={(e) => {
                        const newValue = parseFloat(e.target.value);
                        if (newValue <= sliderMax && newValue >= minPrice) {
                          setMaxPrice(newValue);
                        } else {
                          Swal.fire({
                            icon: "error",
                            title: "Invalid Input",
                            text: `Maximum price must be between ${minPrice} and ${sliderMax}`,
                            confirmButtonColor: "#F6921E",
                            confirmButtonText: "OK",
                          });
                        }
                      }}
                      aria-label="Maximum price input"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filtering;

import React from "react";
import { ToastContainer } from "react-toastify";
import Header from "../components/Header";

const About = () => {
  return (
    <>
      <Header />
      <section className="about-section py-5">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8">
              <div className="card shadow">
                <div className="card-body">
                  <h4 className="card-title text-center mb-4">
                    ABOUT AFRICAMARKETS
                  </h4>
                  {/* card-text lead mb-4 */}
                  <p>
                    Welcome to AfricaMarkets, your top destination for authentic
                    goods and services sourced from Africa and other parts of
                    the world and delivered right to your doorstep. We are not
                    just an e-commerce platform but a gateway that connects you
                    to the rich cultural heritage and vibrant marketplaces of
                    Africa.
                  </p>
                  <p>
                    At AfricaMarkets, we believe in celebrating the diversity of
                    Africa by showcasing its finest products, sourced directly
                    from local artisans, craftsmen, manufacturers, and
                    entrepreneurs from across the continent. From exquisite
                    handmade crafts to sumptuous culinary delights, we stock a
                    wide array of products that embody the spirit and essence of
                    Africa.
                  </p>
                  <p>
                    Our mission is to empower African businesses, both big and
                    small, by providing them with a global platform to showcase
                    their talents and reach a broader audience. By supporting
                    AfricaMarkets, you are not just purchasing products; you are
                    investing in communities, preserving traditional
                    craftsmanship, and contributing to sustainable development
                    across the continent.
                  </p>
                  <p>
                    Transparency, authenticity, and quality are at the core of
                    everything we do. We work closely with our partners to
                    ensure that each product meets our high standards and
                    reflects the true essence of Africa. With secure payment
                    options and reliable worldwide shipping, shopping with
                    AfricaMarkets is convenient, simple, and fun.
                  </p>
                  <p>
                    Whether you are searching for unique gifts, exquisite home
                    décor, African delicacies, or quality services,
                    AfricaMarkets is your one-stop destination for all things
                    African. Join us on this journey of discovery and
                    exploration as we celebrate the beauty and richness of
                    Africa, one product at a time.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  );
};

export default About;

import Sizes from "../Sizes";
import ProductColor from "../ProductColor";
import Material from "../VariationComponent";
import Style from "../Style";
import Theme from "../Theme";
import Thickness from "../Thickness";
import Elasticity from "../Elasticity";
import Fit from "../Fit";
import Neckline from "../Neckline";
import Sleeve from "../Sleeve";
import Pattern from "../Pattern";
import Waistline from "../Waistline";
import Batteries from "../Batteries";
import Uhd from "../Uhd";
import Expirydate from "../Expirydate";
import Finish from "../Finish";
import Packaging from "../Packaging";
import Foundation from "../Foundation";
import Fragrance from "../Fragrance";
import Productiondate from "../Productiondate";
import Accessories from "../Accessories";
import ActivityType from "../ActivityType";
import Amplification from "../Amplification";
import Audio from "../Audio";
import AutomationLevel from "../AutomationLevel";
import BallType from "../BallType";
import BandMaterial from "../BandMaterial";
import BatteryLife from "../BatteryLife";
import BodyType from "../BodyType";
import CameraResolution from "../CameraResolution";
import Capacity from "../Capacity";
import CaseShape from "../CaseShape";
import Certification from "../Certification";
import ClaspType from "../ClaspType";
import ComfortFeatures from "../ComfortFeatures";
import Connectivity from "../Connectivity";
import ControlMethod from "../ControlMethod";
import DisplayType from "../DisplayType";
import DosageForm from "../DosageForm";
import DriveType from "../DriveType";
import DrumConfiguration from "../DrumConfiguration";
import DrumHeads from "../DrumHeads";
import Durability from "../Durability";
import EaseOfUseCampingEquipment from "../EaseOfUseCampingEquipment";
import EaseOfUseExerciseEquipment from "../EaseOfUseExerciseEquipment";
import Effects from "../Effects";
import EnergyEfficiency from "../EnergyEfficiency";
import EngineSize from "../EngineSize";
import Feature from "../Feature";
import Freshness from "../Freshness";
import Fuel from "../Fuel";
import FurnitureType from "../FurnitureType";
import Gemstone from "../Gemstone";
import Gender from "../Gender";
import GraphicsCard from "../GraphicsCard";
import Inflation from "../Inflation";
import Keys from "../Keys";
import LevelSize from "../LevelSize";
import LightingType from "../LevelSize";
import MedicalCondition from "../MedicalCondition";
import MicPlacement from "../MicPlacement";
import Modifications from "../Modifications";
import MountingType from "../MountingType";
import Mouthpiece from "../Mouthpiece";
import Movement from "../Movement";
import OperatingSystem from "../OperatingSystem";
import Pedals from "../Pedals";
import PerformanceFeatures from "../PerformanceFeatures";
import PesticideUse from "../PesticideUse";
import Ports from "../Ports";
import PowerSource from "../PowerSource";
import Processing from "../Processing";
import ProcessorType from "../ProcessorType";
import ProduceClass from "../ProduceClass";
import ProductType from "../ProductType";
import Purpose from "../Purpose";
import RamSize from "../RamSize";
import RefreshRate from "../RefreshRate";
import Ripeness from "../Ripeness";
import SafetyFeatures from "../SafetyFeatures";
import ScreenSize from "../ScreenSize";
import SeasonalUseEquipment from "../SeasonalUseEquipment";
import SeatingCapacity from "../SeatingCapacity";
import SensorType from "../SensorType";
import SpecialFeatures from "../SpecialFeatures";
import StorageCapacity from "../StorageCapacity";
import StorageRequirements from "../StorageRequirements";
import Strings from "../Strings";
import TargetedAudience from "../TargetedAudience";
import Technology from "../Technology";
import TechnologyFeatures from "../TechnologyFeatures";
import Touchscreen from "../Touchscreen";
import TrackingFeatures from "../TrackingFeatures";
import Transmission from "../Transmission";
import Tuning from "../Tuning";
import Usage from "../Usage";
import UsageEnvironment from "../UsageEnvironment";
import UsageSurface from "../UsageSurface";
import Warranty from "../Warranty";
import WaterResistance from "../WaterResistance";
import Weight from "../Weight";
import WirelessConnectivity from "../WirelessConnectivity";
import Wood from "../Wood";
import Skintype from "../Skintype";
import Ingredients from "../Ingredients";

const variations = [
  { Component: Material, key: "Material" },
  { Component: Sizes, key: "size" },
  { Component: ProductColor, key: "productcolor" },
  { Component: Style, key: "style" },
  { Component: Theme, key: "theme" },
  { Component: Thickness, key: "thickness" },
  { Component: Elasticity, key: "elasticity" },
  { Component: Fit, key: "fit" },
  { Component: Neckline, key: "neckline" },
  { Component: Sleeve, key: "sleeve" },
  { Component: Pattern, key: "pattern" },
  { Component: Waistline, key: "waistline" },
  { Component: Batteries, key: "batteries" },
  { Component: Uhd, key: "uhd" },
  { Component: Expirydate, key: "expirydate" },
  { Component: Finish, key: "finish" },
  { Component: Packaging, key: "packaging" },
  { Component: Foundation, key: "foundation" },
  { Component: Fragrance, key: "fragrance" },
  { Component: Productiondate, key: "productiondate" },
  { Component: Accessories, key: "accessories" },
  { Component: ActivityType, key: "activitytype" },
  { Component: Amplification, key: "amplification" },
  { Component: Audio, key: "audio" },
  { Component: AutomationLevel, key: "automationlevel" },
  { Component: BallType, key: "balltype" },
  { Component: BandMaterial, key: "bandmaterial" },
  { Component: BatteryLife, key: "batterylife" },
  { Component: BodyType, key: "bodytype" },
  { Component: CameraResolution, key: "cameraresolution" },
  { Component: Capacity, key: "capacity" },
  { Component: CaseShape, key: "caseshape" },
  { Component: Certification, key: "certification" },
  { Component: ClaspType, key: "clasptype" },
  { Component: ComfortFeatures, key: "comfortfeatures" },
  { Component: Connectivity, key: "connectivity" },
  { Component: ControlMethod, key: "controlmethod" },
  { Component: DisplayType, key: "displaytype" },
  { Component: DosageForm, key: "dosageform" },
  { Component: DriveType, key: "drivetype" },
  { Component: DrumConfiguration, key: "drumconfiguration" },
  { Component: DrumHeads, key: "drumheads" },
  { Component: Durability, key: "durability" },
  { Component: EaseOfUseCampingEquipment, key: "easeofusecampingequipment" },
  { Component: EaseOfUseExerciseEquipment, key: "easeofuseexerciseequipment" },
  { Component: Effects, key: "effects" },
  { Component: EnergyEfficiency, key: "energyefficiency" },
  { Component: EngineSize, key: "enginesize" },
  { Component: Feature, key: "feature" },
  { Component: Freshness, key: "freshness" },
  { Component: Fuel, key: "fuel" },
  { Component: FurnitureType, key: "furnituretype" },
  { Component: Gemstone, key: "gemstone" },
  { Component: Gender, key: "gender" },
  { Component: GraphicsCard, key: "graphicscard" },
  { Component: Inflation, key: "inflation" },
  { Component: Keys, key: "keys" },
  { Component: LevelSize, key: "levelsize" },
  { Component: LightingType, key: "lightingtype" },
  { Component: MedicalCondition, key: "medicalcondition" },
  { Component: MicPlacement, key: "micplacement" },
  { Component: Modifications, key: "modifications" },
  { Component: MountingType, key: "mountingtype" },
  { Component: Mouthpiece, key: "mouthpiece" },
  { Component: Movement, key: "movement" },
  { Component: OperatingSystem, key: "operatingsystem" },
  { Component: Pedals, key: "pedals" },
  { Component: PerformanceFeatures, key: "performancefeatures" },
  { Component: PesticideUse, key: "pesticideuse" },
  { Component: Ports, key: "ports" },
  { Component: PowerSource, key: "powersource" },
  { Component: Processing, key: "processing" },
  { Component: ProcessorType, key: "processortype" },
  { Component: ProduceClass, key: "produceclass" },
  { Component: ProductType, key: "producttype" },
  { Component: Purpose, key: "purpose" },
  { Component: RamSize, key: "ramsize" },
  { Component: RefreshRate, key: "refreshrate" },
  { Component: Ripeness, key: "ripeness" },
  { Component: SafetyFeatures, key: "safetyfeatures" },
  { Component: ScreenSize, key: "screensize" },
  { Component: SeasonalUseEquipment, key: "seasonaluseequipment" },
  { Component: SeatingCapacity, key: "seatingcapacity" },
  { Component: SensorType, key: "sensortype" },
  { Component: SpecialFeatures, key: "specialfeatures" },
  { Component: StorageCapacity, key: "storagecapacity" },
  { Component: StorageRequirements, key: "storagerequirements" },
  { Component: Strings, key: "strings" },
  { Component: TargetedAudience, key: "targetedaudience" },
  { Component: Technology, key: "technology" },
  { Component: TechnologyFeatures, key: "technologyfeatures" },
  { Component: Touchscreen, key: "touchscreen" },
  { Component: TrackingFeatures, key: "trackingfeatures" },
  { Component: Transmission, key: "transmission" },
  { Component: Tuning, key: "tuning" },
  { Component: Usage, key: "usage" },
  { Component: UsageEnvironment, key: "usageenvironment" },
  { Component: UsageSurface, key: "usagesurface" },
  { Component: Warranty, key: "warranty" },
  { Component: WaterResistance, key: "waterresistance" },
  { Component: Weight, key: "weight" },
  { Component: WirelessConnectivity, key: "wirelessconnectivity" },
  { Component: Wood, key: "wood" },
  { Component: Skintype, key: "skintype" },
  { Component: Ingredients, key: "ingredients" },
];

export default variations;

import React from "react";
import { ToastContainer } from "react-toastify";
import Header from "../components/Header";
import { NavLink } from "react-router-dom";

const OrderHow = () => {
  return (
    <>
      <Header />
      <section className="privacy-policy-section">
        <div className="container">
          <div className="row justify-content-center my-5">
            <div className="col-lg-8 col-md-10 col-sm-12">
              <div className="card shadow p-4">
                <div className="card-header text-white text-center">
                  <h5 className="mb-0">HOW TO ORDER</h5>
                </div>
                <div className="card-body">
                  <p>
                    Ordering on AfricaMarkets is quick and simple. Kindly follow
                    these steps to place your order;
                  </p>
                  <h6 className="text-center">
                    Step 1: Visit the e-commerce website
                  </h6>
                  <p>
                    Kindly visit{" "}
                    <a href="/" style={{ color: "blue", fontSize: 15 }}>
                      www.africamarkets.net
                    </a>{" "}
                    to access the platform
                  </p>
                  <h6 className="text-center">Step 2: Select Product(s)</h6>
                  <p>
                    Browse through our wide range of products using the search
                    bar or by navigating through categories. Click on a product
                    to view more details, such as price, description, and
                    available variants.
                  </p>
                  <p>
                    Sign in or Create an Account - If you're a returning
                    customer, sign in to your account using your email address
                    and password, which was created for you (check your email
                    for the login details).
                  </p>
                  <h6 className="text-center">Step 3: Add to Cart</h6>
                  <p>
                    Once you've found the product you want to purchase, select
                    the quantity and any desired options (e.g., size, color) and
                    click on the "Add to Cart" button. You can continue shopping
                    or proceed to checkout.
                  </p>
                  <h6 className="text-center">Step 4: Review Your Cart</h6>
                  <p>
                    Check the items in your cart to ensure everything is
                    accurate. You can update quantities, remove items, or apply
                    promo codes or discounts.
                  </p>
                  <h6 className="text-center">Step 5: Checkout</h6>
                  <p>
                    When you're ready to place your order, click on the
                    "Checkout" button. You'll be prompted to enter your shipping
                    address, select a shipping method, and choose your preferred
                    payment method.
                  </p>
                  <h6 className="text-center">
                    Step 6: Review and Place Order
                  </h6>
                  <p>
                    Review your order summary, including the total amount due,
                    shipping details, and payment information. Make sure all
                    information is accurate, then click on the "Place Order"
                    button to complete your purchase.
                  </p>
                  <h6 className="text-center">Step 7: Confirmation</h6>
                  <p>
                    After placing your order, you'll receive an order
                    confirmation email with details about your purchase,
                    including the order number and estimated delivery date. You
                    can also track the status of your order through your account
                    dashboard with a code provided in the email.
                  </p>
                  <h6 className="text-center">Step 8: Sit Back and Relax</h6>
                  <p>
                    Once your order is processed, our team will handle the rest.
                    Sit back, relax, and await the delivery of your products
                    right to your doorstep.
                  </p>
                  <p>
                    If you have any questions or need assistance at any point
                    during the ordering process, feel free to contact our
                    customer support team for help.{" "}
                    <NavLink
                      to="/contact"
                      style={{ fontSize: 17, color: "black" }}
                    >
                      Contact Us
                    </NavLink>
                  </p>
                  {/* Add more content for the Privacy Policy */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  );
};

export default OrderHow;

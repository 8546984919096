import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { API_BASE_URL } from "../config/connect";

const CheckoutPaySummary = ({
  handlePayment,
  payLoading,
  userInfo,
  payInfo,
  trackInfo,
  clientRegion,
  sellerRegion,
  sendcourierfee,
  sendtaxamount,
  deliverystatus,
}) => {
  const [taxPercentage, setTaxPercentage] = useState(0);
  const [taxAmt, setTaxAmt] = useState(0);
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [enableBtn, setEnableBtn] = useState(false);
  const cartProducts = useSelector((state) => state.cart);
  const cartItems = JSON.parse(localStorage.getItem("cart"));
  const [interregioncourierfee, setInterFee] = useState(0);
  const [intraregioncourierfee, setIntraFee] = useState(0);
  const [regionsss, setRegionsss] = useState([]);

  const getCartProducts =
    cartProducts?.products?.length > 0 ? cartProducts : cartItems;
  // console.log(sellerRegion);
  // console.log(deliverystatus)
  const getTaxes = async () => {
    const res = await fetch(
      `https://africamarkets.net/ghmarketconnect/?ghw=processpayment&svg=gettaxes`
    );
    const response = await res.json();
    return response;
  };

  const getAllRegions = () => {
    fetch(
      `https://africamarkets.net/ghmarketconnect/?ghw=productservices&svg=allregions`
    )
      .then((response) => response.json())
      .then((json) => {
        setRegionsss(json.results);
      })
      .catch((error) => console.error("Error fetching makes:", error));
  };
  const getPlatformFees = () => {
    fetch(
      `https://africamarkets.net/ghmarketconnect/?ghw=productservices&svg=deliveryfees`
    )
      .then((response) => response.json())
      .then((json) => {
        // console.log(json['results'][0]);
        if (json["results"] && json["results"].length > 0) {
          setIntraFee(json["results"][0].intraregioncourierfee);
          setInterFee(json["results"][0].interregioncourierfee);
        }
      });
  };

  // Function to get region name based on region number
  const getRegionName = (number) => {
    const region = regionsss.find((region) => region.code === number);
    return region ? region.name : "Invalid region number";
  };

  // Function to handle tax calculation
  const handleTaxCalculation = async () => {
    try {
      const taxes = await getTaxes();
      const percentage = taxes["data"];
      setTaxPercentage(percentage);
      const amt = ((percentage * getCartProducts?.productsAmt) / 100).toFixed(
        2
      );
      setTaxAmt(amt);
      sendtaxamount(parseFloat(amt) || 0);
    } catch (error) {
      console.error("Error fetching taxes:", error);
    }
  };

  // Function to calculate total delivery fee
  const calculateTotalDeliveryFee = () => {
    let totalDeliveryFee = 0;

    Object.entries(sellerRegion || {}).forEach(([productCode, region]) => {
      const status = deliverystatus[productCode];

      if (region === getRegionName(clientRegion) && status !== "1") {
        totalDeliveryFee += intraregioncourierfee;
      } else if (
        getRegionName(clientRegion) &&
        region !== getRegionName(clientRegion) &&
        status !== "1"
      ) {
        totalDeliveryFee += interregioncourierfee;
      } else if (region === getRegionName(clientRegion) && status === "1") {
        totalDeliveryFee = 0;
      } else if (
        getRegionName(clientRegion) &&
        region !== getRegionName(clientRegion) &&
        status === "1"
      ) {
        totalDeliveryFee = 0;
      } else {
        totalDeliveryFee = 0;
      }
    });

    setDeliveryFee(totalDeliveryFee);
    sendcourierfee(totalDeliveryFee ? parseFloat(totalDeliveryFee) : 0);
  };

  // Function to handle button enabling based on userInfo and payInfo
  const handleButtonEnable = () => {
    if (
      userInfo &&
      payInfo &&
      Object.keys(userInfo).length > 0 &&
      Object.keys(payInfo).length > 0
    ) {
      setEnableBtn(false);
    } else {
      setEnableBtn(true);
    }
  };

  useEffect(() => {
    getAllRegions();
    getPlatformFees();
    handleTaxCalculation();
  }, []);

  // Trigger the delivery fee calculation only if `clientRegion` changes
  useEffect(() => {
    calculateTotalDeliveryFee();
  }, [clientRegion]);

  // Handle button enable logic
  useEffect(() => {
    handleButtonEnable();
  }, [userInfo, payInfo]);

  return (
    <>
      <div className="col-sm-3">
        <div className="card">
          <div className="card-header">Summary</div>
          <div className="card-body">
            <div className="coupon-product-content">
              <div className="cart-summary-item">
                <div className="cart-summary-item-label">Subtotal</div>
                <div className="cart-summary-item-content">
                  GH₵ {(getCartProducts?.productsAmt).toFixed(2)}
                </div>
              </div>
              {taxAmt > 0 && (
                <div className="cart-summary-item mt-2">
                  <div className="cart-summary-item-label">Tax</div>
                  <div className="cart-summary-item-content">GH₵ {taxAmt}</div>
                </div>
              )}
              {clientRegion && sellerRegion && deliverystatus && (
                <div className="cart-summary-item">
                  <div className="cart-summary-item-label">Delivery Fees</div>
                  <div className="cart-summary-item-content">
                    GH₵ {deliveryFee}
                  </div>
                </div>
              )}

              <hr className="mt-15 mb-15" />

              <div className="cart-summary-item">
                <div className="cart-summary-item-label">Total</div>
                <div className="cart-summary-item-content">
                  GH₵{" "}
                  {(
                    parseFloat(getCartProducts?.productsAmt) +
                    parseFloat(taxAmt) +
                    parseFloat(deliveryFee)
                  ).toFixed(2)}
                </div>
              </div>

              {handlePayment !== undefined && (
                <>
                  {!payLoading ? (
                    <button
                      className="btn btn-block mt-5"
                      onClick={() => handlePayment()}
                      disabled={enableBtn}
                    >
                      Pay now (GH₵{getCartProducts?.productsAmt})
                    </button>
                  ) : (
                    <button className="btn btn-block mt-5">
                      Redirecting... (GH₵{getCartProducts?.productsAmt})
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckoutPaySummary;

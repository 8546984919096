import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../config/connect";

const AutomationLevel = ({ initialValue, onSelected }) => {
  const [selectedAutomationLevel, setSelectedAutomationLevel] = useState(
    initialValue || null
  ); // Initialize with initialValue prop
  const [variations, setVariations] = useState([]); // Store the list of automation levels
  const { prodcode } = useParams(); // Extract product code from URL params

  // Fetch product automation level variations
  const getAllProductVariations = async () => {
    try {
      const response = await fetch(
        `https://africamarkets.net/ghmarketconnect/?ghw=productservices&svg=fashionvariations&prcode=${prodcode}`
      );
      const json = await response.json();
      const firstResult = json["results"][0];
      if (firstResult && firstResult.automationlevel) {
        const automationLevelObject = firstResult.automationlevel;
        if (automationLevelObject.trim() !== "") {
          setVariations(automationLevelObject.split(","));
        } else {
          setVariations([]);
        }
      }
    } catch (error) {
      console.error("Error fetching variations:", error); // Log the error for debugging
      setVariations([]);
    }
  };

  // Fetch automation level variations when the product code changes
  useEffect(() => {
    getAllProductVariations();
  }, [prodcode]);

  // Update state when initialValue changes
  useEffect(() => {
    if (initialValue) {
      setSelectedAutomationLevel(initialValue);
    }
  }, [initialValue]);

  // Handle automation level selection toggle
  const handleAutomationLevelClick = (automationlevel) => {
    const newSelected =
      selectedAutomationLevel === automationlevel ? null : automationlevel;
    setSelectedAutomationLevel(newSelected);
    onSelected(newSelected); // Notify parent component
  };

  return variations.length > 0 ? (
    <div
      className="sizes-container"
      style={{ display: "flex", flexDirection: "row" }}
    >
      <span style={{ fontWeight: "bold", color: "black", marginTop: "12px" }}>
        Automation Level:
      </span>
      <div className="sizes" style={{ display: "flex", gap: "8px" }}>
        {variations.map((variation, i) => (
          <div
            key={i}
            className={`size ${selectedAutomationLevel === variation ? "selected" : ""}`}
            style={{
              padding: "8px 12px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              cursor: "pointer",
              backgroundColor:
                selectedAutomationLevel === variation ? "#FF4800" : "#f9f9f9",
              color: selectedAutomationLevel === variation ? "#fff" : "#000",
            }}
            onClick={() => handleAutomationLevelClick(variation)}
          >
            {variation}
          </div>
        ))}
      </div>
    </div>
  ) : null;
};

export default AutomationLevel;

import React from "react";
import { ToastContainer } from "react-toastify";
import Header from "../components/Header";

const Help = () => {
  return (
    <>
      <Header />
      <section className="contact-section py-5">
        <div className="container">
          <div className="row justify-content-center">
            {/* Adjusted column to be responsive */}
            <div className="col-lg-8 col-md-10 col-sm-12">
              <div className="card shadow">
                <div className="card-body">
                  <h2 className="card-title text-center mb-4">Help Line</h2>
                  {/* Email section */}
                  <p className="card-text lead text-center mb-4">
                    <strong>Email:</strong> support@africamarkets.net
                  </p>
                  {/* Uncommented this for Location if needed */}
                  {/* <p className='card-text lead text-center mb-4'>
										<strong>Location:</strong> V357 Mabel ST GS-0330-8861, The Oyster Shell, 41 A McCarthy Hill, Accra, Ghana.
									</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ToastContainer />
    </>
  );
};

export default Help;

import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ProductVariations from "./ProductVariations";
import { NavLink } from "react-router-dom";

function VariationModal({ onSelectVariations }) {
  const [show, setShow] = useState(false);
  const [selectedVariations, setSelectedVariations] = useState(() => {
    // Load initial state from localStorage
    const savedVariations = localStorage.getItem("selectedVariations");
    return savedVariations ? JSON.parse(savedVariations) : {};
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Save selected variations to localStorage whenever they change
  useEffect(() => {
    localStorage.setItem(
      "selectedVariations",
      JSON.stringify(selectedVariations)
    );
  }, [selectedVariations]);

  // Update selected variations
  const handleVariationUpdate = (key, value) => {
    setSelectedVariations((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <>
      <div className="variations-btn" onClick={handleShow}>
        <NavLink className="wishlist-btn px-5">
          <i className=""></i> Select Variations
        </NavLink>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "19px" }}>
            Select Variation(s)
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProductVariations
            onSelectVariations={onSelectVariations}
            // onVariationUpdate={handleVariationUpdate}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default VariationModal;

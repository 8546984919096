import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../config/connect";
import variations from "./variations/variationdata/AllVariations";
import Variations from "./variations/VariationComponent";

const ProductVariations = ({ onSelectVariations }) => {
  const { prodcode } = useParams();
  const [selectedVariations, setSelectedVariations] = useState({});
  const [sku, setSKU] = useState("");
  const [sellerregion, setRegion] = useState("");
  const [model, setModel] = useState("");
  const [loading, setLoading] = useState(true);

  // Fetch product details
  useEffect(() => {
    const savedData =
      JSON.parse(localStorage.getItem("productVariations")) || {};
    const savedVariations = savedData[prodcode] || {};

    if (Object.keys(savedVariations).length > 0) {
      setSelectedVariations(savedVariations);
      setLoading(false);
    } else {
      const getAllProducts = async () => {
        try {
          const response = await fetch(
            `https://africamarkets.net/ghmarketconnect/?ghw=productservices&svg=details&prcode=${prodcode}`
          );
          const json = await response.json();
          const product = json.results[0];
          if (product) {
            setSKU(product.sku);
            setRegion(product.region);
            setModel(product.modelname);
          }
        } catch (error) {
          console.error("Error fetching product details:", error);
        } finally {
          setLoading(false);
        }
      };

      getAllProducts();
    }
  }, [prodcode]);

  // Save variations by prodcode in localStorage
  useEffect(() => {
    const savedData =
      JSON.parse(localStorage.getItem("productVariations")) || {};
    savedData[prodcode] = selectedVariations;
    localStorage.setItem("productVariations", JSON.stringify(savedData));
  }, [prodcode, selectedVariations]);

  // Handle variation updates
  const handleVariationUpdate = (key, value) => {
    setSelectedVariations((prev) => {
      const updated = { ...prev, [key]: value };

      onSelectVariations(updated);

      return updated;
    });
  };

  return (
    <ul>
      {variations.map(({ Component, key }, index) => (
        <li className="sd-category" key={index}>
          <Variations
            type={key}
            initialValue={selectedVariations[key] || null}
            onSelected={(value) => handleVariationUpdate(key, value)}
          />
        </li>
      ))}
    </ul>
  );
};

export default ProductVariations;

import React, { useState, useEffect } from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import ProdImagesSkeleton from "./ProdImagesSkeleton";
import { API_BASE_URL } from "../config/connect";

function Productsimages({ productcode, openModal, getImages }) {
  const [proimage, setProimage] = useState([]);
  useEffect(() => {
    const getAllImages = () => {
      try {
        fetch(
          `https://africamarkets.net/ghmarketconnect/?ghw=productservices&svg=subimages&prcode=${productcode}`
        )
          .then((response) => response.json())
          .then((json) => {
            setProimage(json["results"]);
            getImages(json["results"]);
          });
      } catch (error) {
        console.error("Internet Connection Down", error);
        throw error;
      }
    };

    getAllImages();
  }, [productcode]);

  return (
    <div>
      <div className="shop-details-nav-wrap">
        <nav>
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            {proimage?.length > 0 ? (
              proimage?.map((post, index) => (
                <button
                  key={index}
                  className="nav-link"
                  onClick={() => openModal(index)}
                  type="button"
                  role="tab"
                  aria-controls={`nav-item-${index + 1}`}
                >
                  <img
                    src={post.image}
                    alt={`img-${index}`}
                    width="100px"
                    height="100px"
                  />
                </button>
              ))
            ) : (
              <ProdImagesSkeleton />
            )}
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Productsimages;

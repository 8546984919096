import React from "react";
import { ToastContainer } from "react-toastify";
import Header from "../components/Header";
import { NavLink } from "react-router-dom";

const FAQ = () => {
  return (
    <>
      <Header />
      <section className="faq-section m-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1 col-sm-12">
              <div className="card shadow">
                <div className="card-body">
                  <h4 className="text-center mb-4">
                    FREQUENTLY ASKED QUESTIONS (FAQ)
                  </h4>
                  <p>
                    Welcome to the FAQ section of AfricaMarkets. Here, we’ve
                    compiled answers to frequently asked questions to help make
                    your shopping experience easier and more enjoyable. If you
                    have any question(s) not found here, feel free to contact
                    our customer support team for assistance.
                  </p>
                  <div className="faq-category">
                    <h5 className="text-center mb-3">General Questions</h5>
                    <div className="faq-item">
                      <h5>How do I create an account on your platform?</h5>
                      <p>
                        A: Creating an account is easy! Simply purchase an item
                        or pay for a service on africamarkets.net, and an
                        account will be automatically created for you with the
                        email of your choice and a system-generated password
                        which you are allowed to reset when you login into that
                        account and the details of the account are sent to your
                        email.
                      </p>
                    </div>
                    <div className="faq-item">
                      <h5>
                        Can I shop on your platform without creating an account?
                      </h5>
                      <p>
                        A: Yes, you can browse and shop without an account.
                        However, creating an account allows you to track your
                        orders and save preferences.
                      </p>
                    </div>
                    <div className="faq-item">
                      <h5>Is it safe to shop on your platform?</h5>
                      <p>
                        A: Absolutely! We use industry-standard encryption and
                        security measures to protect your personal and payment
                        information to ensure a safe shopping experience.
                      </p>
                    </div>
                  </div>
                  <div className="faq-category">
                    <h5 className="text-center mb-3">Ordering and Payment</h5>
                    <div className="faq-item">
                      <h5>How do I place an order?</h5>
                      <p>
                        A: To place an order, simply browse our products, add
                        items to your cart, and proceed to checkout. Follow the
                        prompts to enter your shipping address and payment
                        information.
                      </p>
                    </div>
                    <div className="faq-item">
                      <h5>What payment methods do you accept?</h5>
                      <p>
                        A: We accept various payment methods, including
                        credit/debit cards, PayPal, bank transfer, and mobile
                        wallet options like Apple Pay, Google Pay, and Mobile
                        Money.
                      </p>
                    </div>
                    <div className="faq-item">
                      <h5>Can I track my order?</h5>
                      <p>
                        A: Yes, once your order is shipped, you’ll receive a
                        tracking number via email. You can use this tracking
                        number to monitor the status of your order.
                      </p>
                    </div>
                  </div>
                  <div className="faq-category">
                    <h5 className="text-center mb-3">Shipping and Delivery</h5>
                    <div className="faq-item">
                      <h5>How long does shipping take?</h5>
                      <p>
                        A: Shipping times vary depending on your location,
                        selected shipping method, and product availability. You
                        can find estimated delivery times at checkout.
                      </p>
                    </div>
                    <div className="faq-item">
                      <h5>Do you offer international shipping?</h5>
                      <p>
                        A: Yes, we offer international shipping to select
                        countries. International shipping rates and delivery
                        times may vary.
                      </p>
                    </div>
                  </div>
                  <div className="faq-category">
                    <h5 className="text-center mb-3">Returns and Refunds</h5>
                    <div className="faq-item">
                      <h5>What is your return policy?</h5>
                      <p>
                        A: We offer a hassle-free return policy. You can
                        initiate a return within a specified timeframe from the
                        date of delivery. Please refer to our{" "}
                        <NavLink to="/return" style={{ fontSize: 17 }}>
                          Return Policy
                        </NavLink>{" "}
                        for more details.
                      </p>
                    </div>
                    <div className="faq-item">
                      <h5>How do I return an item?</h5>
                      <p>
                        A: To initiate a return, please contact our customer
                        support team and provide your order number and reason
                        for the return. They will guide you through the return
                        process.{" "}
                        <NavLink to="/contact" style={{ fontSize: 17 }}>
                          Contact Us
                        </NavLink>
                      </p>
                    </div>
                    <div className="faq-item">
                      <h5>When will I receive a refund for my return?</h5>
                      <p>
                        A: Once your return is approved, refunds are typically
                        processed within a certain timeframe. Please allow for
                        processing time and check your original payment method
                        for the refund.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  );
};

export default FAQ;

import React, { createContext, useContext, useEffect, useState } from "react";
import { API_BASE_URL } from "../config/connect";
import Loader from "../views/Loader";
import useFetch from "../hooks/useFetch";

const AllProductContext = createContext();

export function useProductData() {
  return useContext(AllProductContext);
}

export const ProductsContextApi = ({ children }) => {
  // const [allProductData, setAllproductData] = useState(null);
  // const [allproImage, setAllProimage] = useState([]);
  // const [subcategories, setsubCategories] = useState("");
  const [AdsSlids, setAdsservice] = useState("");
  // const [loading1, setLoading1] = useState(true);
  // const [loading2, setLoading2] = useState(true);
  // const [loading3, setLoading3] = useState(true);
  const [loading4, setLoading4] = useState(true);

  // const getAllProducts = () => {
  //   fetch(`https://africamarkets.net/ghmarketconnect/?ghw=productservices&svg=allproduct`)
  //     .then((response) => response.json())
  //     .then((json) => {
  //       setAllproductData(json["results"]);
  //       console.log(json["results"]);
  //       setLoading1(false);
  //     });
  // };

  const { data: subcategories, loading: loading2 } = useFetch(
    `https://africamarkets.net/ghmarketconnect/?ghw=subcategories&svg=list`,
    {},
    [],
    "subcategories"
  );

  const { data: brands, loading: loading3 } = useFetch(
    `https://africamarkets.net/ghmarketconnect/?ghw=productbrand&svg=list`,
    {},
    [],
    "brands"
  );

  const getAllAdsServices = () => {
    fetch(
      `https://africamarkets.net/ghmarketconnect/?ghw=productadsservices&svg=productadsservices`
    )
      .then((response) => response.json())
      .then((json) => {
        const uniqueAds = json["results"]
          .filter(
            (ad, index, self) =>
              index === self.findIndex((a) => a.picture === ad.picture)
          )
          .slice(0, 12);
        setAdsservice(uniqueAds);
        setLoading4(false);
      })
      .catch((error) => {
        console.error("Error fetching ads:", error);
      });
  };

  useEffect(() => {
    getAllAdsServices();
  }, []);

  // const getAllSubCategories = () => {
  //   fetch(`https://africamarkets.net/ghmarketconnect/?ghw=subcategories&svg=list`)
  //     .then((response) => response.json())
  //     .then((json) => {
  //       //console.log(json['results'])
  //       setsubCategories(json["results"]);
  //       setLoading2(false)
  //     });
  // };

  // const getAllBrands = () => {
  //   fetch(`https://africamarkets.net/ghmarketconnect/?ghw=productbrand&svg=list`)
  //     .then((response) => response.json())
  //     .then((json) => {
  //       //console.log(json['results'])
  //       setBrands(json["results"]);
  //       setLoading3(false)
  //     });
  // };

  // const getAllImages = () => {
  //   try {
  //     setTimeout(() => {
  //     fetch(`https://africamarkets.net/ghmarketconnect/?ghw=productservices&svg=subimages`)
  //       .then((response) => response.json())
  //       .then((json) => {
  //         console.log(json);
  //         setAllProimage(json["results"]);
  //         setLoading4(false);
  //       });
  //     }, 20000);
  //   } catch (error) {
  //     console.error("Internet Connection Down", error);
  //     throw error;
  //   }
  // };

  // useEffect(() => {

  //   // getAllImages();
  //   // getAllProducts();
  //   getAllBrands();
  //   getAllSubCategories();
  // }, []);

  if (loading2 || loading3 || loading4) {
    // Return loading indicator if either of the contexts is still loading
    return <Loader />;
  }

  return (
    <AllProductContext.Provider value={{ subcategories, brands, AdsSlids }}>
      {children}
    </AllProductContext.Provider>
  );
};

// export default ProductsContextApi;

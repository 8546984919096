import React from "react";
import { ToastContainer } from "react-toastify";
import Header from "../components/Header";

const PaymentMethods = () => {
  return (
    <>
      <Header />
      <section className="payment-methods-section py-5">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8">
              <div className="card shadow p-4">
                <div className="card-header text-white text-center">
                  <h5 className="mb-0">Payment Process on Africa Market</h5>
                </div>
                <div className="card-body">
                  <p className="lead">
                    Purchasing products on AfricaMarkets is convenient and
                    secure. Here's how the payment process works:
                  </p>
                  <ol>
                    <li>
                      <strong>Checkout</strong>
                      <br />
                      <ul>
                        <li>
                          Once you've added your items to the cart and reviewed
                          your order, proceed to the checkout page.
                        </li>
                      </ul>
                      <ul>
                        <li>
                          Here, you'll find a detailed summary of your order,
                          including the total amount due.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <br />
                      <strong>Order Confirmation</strong>
                      <br />
                      <ul>
                        <li>
                          You will receive an email with the details of your
                          order to confirm the products you selected.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <br />
                      <strong>Payment Options</strong>
                      <br />
                      <ul>
                        <li>
                          Upon delivery, you can choose to make payment using
                          Mobile Money or pay directly to the rider with cash.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <br />
                      <strong>Order Verification</strong>
                      <br />
                      <ul>
                        <li>
                          A special code will be sent to you by Africa Markets.
                          Share this code with the rider to confirm your order
                          upon delivery.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <br />
                      <strong>Enjoy Your Purchase!</strong>
                      <br />
                      <ul>
                        <li>
                          Once your order has been successfully delivered, enjoy
                          your products!
                        </li>
                        <li>
                          If you have any questions or concerns about your order
                          or payment, our friendly customer support team is
                          ready to assist you.
                        </li>
                      </ul>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  );
};

export default PaymentMethods;

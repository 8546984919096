import React, { useState, useRef, useEffect } from "react";
import Header from "../components/Header";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import momo from "../assets/img/payment/momo.webp";
import vodacash from "../assets/img/payment/vodacash.png";
import atmoney from "../assets/img/payment/atmoney.png";
import cards from "../assets/img/payment/cards.png";
import validator from "validator";
import { API_BASE_URL } from "../config/connect";
import CheckoutPaySummary from "../components/CheckoutPaySummary";
import ReCAPTCHA from "react-google-recaptcha";
import Select from "react-select";
import { useMediaQuery } from "react-responsive";
const CheckoutAddress = () => {
  const recaptcha = useRef();
  const navigate = useNavigate();
  const [toggleDisplay, setToggleDisplay] = useState("none");
  const cartProducts = useSelector((state) => state.cart);
  const cartItems = JSON.parse(localStorage.getItem("cart"));
  const [loading, setLoading] = useState(false);
  // const [mobilemoneycontact, setMobileMoneyContact] = useState("");
  const [username, setUsername] = useState("");
  const notifyError = (msg) => toast.error(msg);
  const [message, setMessage] = useState("");
  const [emailconfirmmsg, setConfirmMessage] = useState("");
  const [validinput, setValidinput] = useState(true);
  const [confirmEmail, setConfirmEmail] = useState(""); // Separate state for confirm email
  const [sellerRegion, setSellerRegion] = useState("");
  const [productRegions, setProductRegions] = useState({});
  const Swal = require("sweetalert2");
  const [productfreedelivery, setFreedelivery] = useState({});
  const [regions, setRegions] = useState([]);
  const [cities, setCities] = useState([]);
  const [zone, setZones] = useState([]);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const getCartProducts =
    cartProducts.products.length > 0 ? cartProducts : cartItems;

  const [result, setResult] = useState({});
  const [data, setData] = useState({
    fname: "",
    lname: "",
    phonenumber: "",
    email: "",
    address: "",
    region: "",
    city: "",
    cart: getCartProducts,
  });
  const [taxPercentage, setTaxPercentage] = useState(0);
  const [taxAmt, setTaxAmt] = useState(0);
  const [importeddeliveryfee, setImportedDeliveryfee] = useState(0);
  const [importedtaxamt, setImportedTaxAmt] = useState(0);

  const receivedeliveryfee = (fee) => {
    const actualfee = parseFloat(fee) > 0 ? fee : 0;
    //console.log(actualfee);
    setImportedDeliveryfee(actualfee);
  };

  const receivetaxamount = (taxamt) => {
    const actualtax = parseFloat(taxamt) > 0 ? taxamt : 0;
    //console.log(actualfee);
    setImportedTaxAmt(actualtax);
  };

  const getAllRegions = () => {
    fetch(
      `https://africamarkets.net/ghmarketconnect/?ghw=productservices&svg=allregions`
    )
      .then((response) => response.json())
      .then((json) => {
        setRegions(json.results);
      })
      .catch((error) => console.error("Error fetching makes:", error));
  };

  const getAllCities = (regioncode) => {
    fetch(
      `https://africamarkets.net/ghmarketconnect/?ghw=productservices&svg=allregioncities&regioncode=${regioncode}`
    )
      .then((response) => response.json())
      .then((json) => {
        setCities(json.results);
      })
      .catch((error) => console.error("Error fetching models:", error));
  };

  const getZone = (zoneid) => {
    fetch(
      `https://africamarkets.net/ghmarketconnect/?ghw=productservices&svg=getzone&zoneid=${zoneid}`
    )
      .then((response) => response.json())
      .then((json) => {
        setZones(json.price);
      })
      .catch((error) => console.error("Error fetching models:", error));
  };

  useEffect(() => {
    getAllRegions();
  }, []);
  const handleInput = (input, actionMeta) => {
    // If it's a native <select> event
    if (input && input.target) {
      const { name, value } = input.target;

      if (name === "email") {
        if (!validator.isEmail(value)) {
          setMessage("Please, enter a valid email!");
          setValidinput(false);
        } else {
          setMessage("");
        }
      }

      if (name === "region") {
        getAllCities(value);
      }

      if (name === "city") {
        // Get the selected option's data-zone attribute
        const selectedZone =
          input.target.selectedOptions[0]?.getAttribute("data-zone");
        if (selectedZone) {
          getZone(selectedZone);
        }
      }

      // Update the data state
      setData((data) => ({
        ...data,
        [name]: value,
        courierfee: importeddeliveryfee,
        taxesamt: importedtaxamt,
      }));
    } else {
      // If it's coming from react-select
      const { name } = actionMeta || {};
      const { value, zone } = input || {}; // input is the selected option
      // console.log(name + value + zone)
      if (name === "email") {
        if (!validator.isEmail(value)) {
          setMessage("Please, enter a valid email!");
          setValidinput(false);
        } else {
          setMessage("");
        }
      }

      if (name === "region") {
        getAllCities(value);
      }

      if (name === "city") {
        // Use the zone value from the selected option in react-select
        if (zone) {
          getZone(zone);
        }
      }

      // Update the data state
      setData((data) => ({
        ...data,
        [name]: value,
        courierfee: importeddeliveryfee,
        taxesamt: importedtaxamt,
      }));
    }
  };

  // Transform your city data into options for react-select
  const cityOptions = cities.map((city) => ({
    value: city.name,
    label: city.name,
    zone: city.zonecode, // Store additional data like zone code if needed
  }));

  const regionOptions = regions.map((region) => ({
    value: region.code,
    label: region.name,
  }));

  // const handleChange = (event) => {
  //   const { name, value } = event.target;
  //   const data_name = event.target.attributes.payment_method.name;
  //   const data_value = event.target.attributes.payment_method.value;

  //   event.target.value === "momo" ||
  //   event.target.value === "vodacash" ||
  //   event.target.value === "atmoney"
  //     ? setToggleDisplay("block")
  //     : setToggleDisplay("none");
  //   setMobileMoneyContact("");
  // };

  // const notify = (mode, msg) => (mode !== "" && msg ? toast[mode](msg) : "");

  // Update handleEmail
  const handleEmail = (event) => {
    const emailValidity = event.target.value;
    setConfirmEmail(emailValidity); // Update confirm email state
    if (data.email !== emailValidity) {
      setConfirmMessage("Email mismatch!");
      setValidinput(false);
    } else {
      setConfirmMessage("");
      setValidinput(true);
    }
  };
  const handleCustomerDetail = async () => {
    // Check if any input field is empty
    if (
      !data.fname ||
      !data.lname ||
      !data.phonenumber ||
      !data.email ||
      !confirmEmail ||
      !data.address ||
      !data.region ||
      !data.city
    ) {
      notifyError("Please fill out all fields!");
      return;
    }

    if (!importeddeliveryfee) {
      notifyError("Missing delivery fee!");
      return;
    }

    // Check if at least one radio button is checked
    // const paymentOption = document.querySelector(
    //   'input[name="payment_option"]:checked'
    // );

    //check payment method
    // if (!paymentOption) {
    //   notifyError("Please select a payment method!");
    //   return;
    // }

    //check email errors
    if (!validinput) {
      notifyError("There is an error in your form!");
      return;
    }
    //check if recaptcha has been confirmed
    const captchaValue = recaptcha.current.getValue();
    if (captchaValue) {
      setLoading(true);

      const res = await fetch(
        //`https://africamarkets.net/ghmarketconnect/?ghw=processpayment&svg=cusdetails`,
        `https://africamarkets.net/ghmarketconnect/?ghw=processpayment&svg=pay`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const response = await res.json();
      setResult(response.data);
      // console.log(response.data);
      const paylink = response.data["paylink"];

      if (response) {
        // let mode = result["error"] === "1" ? "success" : "error";
        // notify(mode, result["msg"]);
        // const dlres = response.data;
        setLoading(false);

        // save client info to localstorage
        //localStorage.setItem(btoa("cdi"), JSON.stringify(response.data));

        // unset user input
        setData({
          fname: "",
          lname: "",
          phonenumber: "",
          email: "",
          address: "",
          region: "",
          city: "",
          // cart: getCartProducts,
        });
      }

      if (paylink) {
        //console.log(paylink);
        localStorage.removeItem("cart");
        window.location.assign(paylink);

        //   Swal.fire({
        //     title: "Order Sent!!",
        //     html: "Your order was saved successfully.<br /> 1- You are required to pay on delivery.<br /> 2- Receive your item(s) after providing your delivery code to the courier service",
        //     type: "success"
        // }).then(function() {
        //     window.location = "/";
        // });
      }
    } else {
      notifyError("Please verify the reCAPTCHA!");
    }
  };

  useEffect(() => {
    receivedeliveryfee();
    const storedUsername = localStorage.getItem("username");
    if (storedUsername) {
      setUsername(storedUsername);
      getCustomerDetails();
    }
    getProductRegions();
  }, []);

  //check if the user is already logged in and populate details in the fields
  // const getProductDetails = () => {
  //   fetch(
  //     `https://africamarkets.net/ghmarketconnect/?ghw=productservices&svg=details&prcode=${getCartProducts.products[0]?.prodcode}`
  //   )
  //     .then((response) => response.json())
  //     .then((json) => {
  //       // console.log(json['results'][0]);
  //       if (json["results"] && json["results"].length > 0) {
  //         setSellerRegion(json['results'][0].region)
  //       }
  //     });
  // };

  // Function to calculate product regions
  const getProductRegions = async () => {
    const regions = {};
    const freedelivery = {};
    await Promise.all(
      getCartProducts.products.map(async (product) => {
        try {
          const response = await fetch(
            `https://africamarkets.net/ghmarketconnect/?ghw=productservices&svg=details&prcode=${product.prodcode}`
          );
          const json = await response.json();
          if (json.results && json.results.length > 0) {
            // console.log(json.results[0].region);
            // console.log(json.results[0].freedelivery);
            regions[product.prodcode] = json.results[0].region;
            freedelivery[product.prodcode] = json.results[0].freedelivery;
          }
        } catch (error) {
          console.error("Error fetching product details:", error);
        }
      })
    );
    setProductRegions(regions);
    setFreedelivery(freedelivery);
  };
  // console.log(productRegions)

  //check if the user is already logged in and populate details in the fields
  const getCustomerDetails = () => {
    fetch(
      `https://africamarkets.net/ghmarketconnect/?ghw=login&svg=get_cust_details&email=${localStorage.getItem("username")}`
    )
      .then((response) => response.json())
      .then((json) => {
        // console.log(json['results'][0]);
        if (json["results"] && json["results"].length > 0) {
          setData((prevData) => ({
            ...prevData,
            fname: json["results"][0].firstname,
            lname: json["results"][0].lastname,
            phonenumber: json["results"][0].phonenumber,
            email: json["results"][0].email,
            address: json["results"][0].address,
            // confirmemail: json["results"][0].email,
            region: json["results"][0].state,
            city: json["results"][0].city,
          }));

          setConfirmEmail(json["results"][0].email);
        }
      });
  };

  return (
    <>
      <Header />

      <section className="coupon-product-area pt-30 pb-60">
        <div className="container pb-30">
          <div className="row mb-1">
            <NavLink onClick={() => navigate(-1)}>
              <span>
                <i className="fa fa-arrow-left"></i> Go back & continue shopping
              </span>
            </NavLink>
          </div>

          <div className="row">
            <div className="col-sm-9 mb-20">
              <div className="card">
                <div className="card-header d-flex justify-content-between">
                  Customer Details
                  {/* <span className="">
                    <NavLink to="/checkout/address">
                      Add Customer Details
                    </NavLink>
                  </span> */}
                </div>
                <div className="card-body">
                  <h5 className="card-title">Add Details</h5>
                  <form>
                    <div className="form-row ">
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-6 mb-3">
                            <label htmlFor="fname">First Name</label>
                            <input
                              type="text"
                              className="form-control"
                              id="fname"
                              name="fname"
                              value={data.fname}
                              placeholder="First Name"
                              onChange={(event) => handleInput(event)}
                              required
                            />
                          </div>
                          <div className="col-md-6 mb-3">
                            <label htmlFor="lname">Last Name</label>
                            <input
                              type="text"
                              className="form-control"
                              id="lname"
                              name="lname"
                              value={data.lname}
                              placeholder="Last Name"
                              onChange={(event) => handleInput(event)}
                              required
                            />
                          </div>
                          <div className="col-md-6 mb-3">
                            <label htmlFor="phonenumber">Phone Number</label>
                            <input
                              type="text"
                              className="form-control"
                              id="phonenumber"
                              name="phonenumber"
                              value={data.phonenumber}
                              placeholder="Phone Number"
                              onChange={(event) => handleInput(event)}
                              required
                            />
                          </div>
                          <div className="col-md-6 mb-3">
                            <label htmlFor="email">
                              Email{" "}
                              <span style={{ color: "red" }}> {message} </span>{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="email"
                              name="email"
                              value={data.email}
                              placeholder="Email"
                              onChange={(event) => handleInput(event)}
                              required
                            />
                          </div>
                          <div className="col-md-6 mb-3">
                            <label htmlFor="confirmemail">
                              Confirm Email{" "}
                              <span style={{ color: "red" }}>
                                {" "}
                                {emailconfirmmsg}{" "}
                              </span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="confirmemail"
                              name="confirmemail"
                              value={confirmEmail}
                              placeholder="Confirm Email"
                              onChange={(event) => handleEmail(event)}
                              required
                            />
                          </div>

                          <div className="col-md-6 mb-3">
                            <label htmlFor="address">Delivery Address</label>
                            <input
                              type="text"
                              className="form-control"
                              id="address"
                              name="address"
                              value={data.address}
                              placeholder="1234 Main St"
                              onChange={(event) => handleInput(event)}
                              required
                            />
                          </div>
                          <div className="col-md-6 mb-3">
                            <label htmlFor="region">Region</label>
                            <Select
                              id="region"
                              name="region"
                              value={regionOptions.find(
                                (option) => option.value === data.region
                              )}
                              onChange={(selectedOption) => {
                                handleInput(selectedOption, { name: "region" });

                                // Check if there are no cities for the selected region
                                if (cityOptions.length === 0) {
                                  // Update the state to show a placeholder if no cities are available
                                  setData((prevData) => ({
                                    ...prevData,
                                    city: "", // Clear any previously selected city
                                  }));
                                }
                              }}
                              options={regionOptions}
                              className="form-control"
                              placeholder="Select a Region"
                              required
                            />
                          </div>

                          <div className="col-md-6 mb-3">
                            <label htmlFor="city">City</label>
                            <Select
                              id="city"
                              name="city"
                              value={
                                cityOptions.length > 0
                                  ? cityOptions.find(
                                      (option) => option.value === data.city
                                    )
                                  : null // Clear the selection if no cities are available
                              }
                              onChange={(selectedOption) =>
                                handleInput(selectedOption, { name: "city" })
                              }
                              options={cityOptions}
                              className="form-control"
                              placeholder={
                                cityOptions.length === 0
                                  ? "No cities available"
                                  : "Select a City"
                              }
                              isDisabled={cityOptions.length === 0} // Disable if no cities are available
                              required
                            />
                          </div>
                        </div>
                        {data.state}
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="card">
                {/* <div className="card-header">Payment method</div> */}
                {/* <div className="card-body">

                  <p className="text-capitalize mt-2">
                    Select a payment method to proceed
                  </p>

                  <div className="d-flex flex-wrap gap-2 align-items-center mb-4 ">
                    <h5 className="mb-0 text-capitalize">Pay via Cards</h5>
                    <span className="fs-10 text-capitalize mt-1">
                      (Visa &amp; Mastercard)
                    </span>
                  </div>

                  <div className="d-flex flex-wrap gap-3 mb-5">
                    <div id="cod-for-cart">
                      <div className="card cursor-pointer">
                        <label className="m-0">
                          <input
                            type="hidden"
                            name="payment_method"
                            value="cards_payment"
                          />
                          <span className="btn-plain btn-block click-if-alone d-flex gap-2 align-items-center cursor-pointer">
                            <input
                              type="radio"
                              id="cards_payment"
                              className="custom-radio"
                              name="payment_option"
                              value="card"
                              payment_method="card"
                              onChange={(event) => handleInput(event)}
                            />
                            <img width="50" src={cards} alt="" />
                            <span className="fs-12">Cards</span>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex flex-wrap gap-2 align-items-center mb-4 ">
                    <h5 className="mb-0 text-capitalize">
                      Pay via mobile money
                    </h5>
                    <span className="fs-10 text-capitalize mt-1">
                      (Faster &amp; secure way to pay bill)
                    </span>
                  </div>

                  <div className="row gx-4 mb-4">
                    <div className="col-sm-6">
                      <input
                        type="hidden"
                        name="payment_method"
                        value="mobilemoney"
                      />
                      <label className="d-flex align-items-center gap-2 mb-0 form-check py-2 cursor-pointer">
                        <input
                          type="radio"
                          id="mobilemoney"
                          name="payment_option"
                          className="form-check-input custom-radio"
                          value="momo"
                          payment_method="mobile money"
                          onChange={(event) => handleChange(event)}
                        />
                        <img width="30" src={momo} alt="" />
                        <span className="text-capitalize form-check-label">
                          MTN mobile money
                        </span>
                      </label>
                    </div>
                    <div className="col-sm-6">
                      <input
                        type="hidden"
                        name="payment_method"
                        value="mobilemoney"
                      />

                      <label className="d-flex align-items-center gap-2 mb-0 form-check py-2 cursor-pointer">
                        <input
                          type="radio"
                          id="mobilemoney"
                          name="payment_option"
                          className="form-check-input custom-radio"
                          value="vodacash"
                          payment_method="mobile money"
                          onChange={(event) => handleChange(event)}
                        />
                        <img width="30" src={vodacash} alt="" />
                        <span className="text-capitalize form-check-label">
                          Vodacash
                        </span>
                      </label>
                    </div>
                    <div className="col-sm-6">
                      <input
                        type="hidden"
                        name="payment_method"
                        value="mobilemoney"
                      />

                      <label className="d-flex align-items-center gap-2 mb-0 form-check py-2 cursor-pointer">
                        <input
                          type="radio"
                          id="mobilemoney"
                          name="payment_option"
                          className="form-check-input custom-radio"
                          value="atmoney"
                          payment_method="mobile money"
                          onChange={(event) => handleChange(event)}
                        />
                        <img width="50" src={atmoney} alt="" />
                        <span className="text-capitalize form-check-label">
                          ATMoney
                        </span>
                      </label>
                    </div>
                  </div>
                  <div
                    className="mobile-money-toggle"
                    style={{ display: toggleDisplay }}
                  >
                    <hr />
                    <div className="col-sm-6">
                      <label htmlFor="mobile_money_contact">
                        Mobile Money Contact
                      </label>
                      <input
                        type="text"
                        id="mobile_money_contact"
                        name="mobile_money_contact"
                        className="form-control"
                        placeholder="0200000000"
                        onChange={handleInput}
                      />
                    </div>
                  </div>
                </div> */}

                <div className=" custom-footer   ">
                  <div>
                    {" "}
                    <ReCAPTCHA
                      ref={recaptcha}
                      sitekey={process.env.REACT_APP_SITE_KEY}
                    />
                  </div>
                  <div>
                    {" "}
                    <button
                      type="submit"
                      className="btn btn-outline-primary"
                      style={{ backgroundColor: "#000" }}
                      onClick={() => navigate(-1)}
                    >
                      Back
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      style={{ backgroundColor: "#F6921E" }}
                      onClick={() => handleCustomerDetail()}
                    >
                      {loading ? (
                        <>
                          <i className="fas fa-circle-notch fa-spin"></i>{" "}
                          Processing...
                        </>
                      ) : (
                        <>
                          <i className="fa-solid fa-save"></i> Send Order
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <CheckoutPaySummary
              clientRegion={data.region}
              sellerRegion={productRegions}
              sendcourierfee={receivedeliveryfee}
              sendtaxamount={receivetaxamount}
              deliverystatus={productfreedelivery}
            />
          </div>
        </div>
      </section>

      <ToastContainer />
    </>
  );
};

export default CheckoutAddress;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../config/connect";

const ProcessorType = ({ onProcessorTypeSelected }) => {
  const [selectedProcessorType, setSelectedProcessorType] = useState(null);
  const [variations, setVariations] = useState([]);
  const { prodcode } = useParams();

  const getAllProductVariations = async () => {
    try {
      const response = await fetch(
        `https://africamarkets.net/ghmarketconnect/?ghw=productservices&svg=fashionvariations&prcode=${prodcode}`
      );
      const json = await response.json();
      const firstResult = json["results"][0];
      if (firstResult && firstResult.processortype) {
        const processorTypeObject = firstResult.processortype;
        if (processorTypeObject.trim() !== "") {
          setVariations(processorTypeObject.split(","));
        }
      }
    } catch (error) {
      console.error("Error fetching product variations:", error);
    }
  };

  useEffect(() => {
    getAllProductVariations();
  }, [prodcode]);

  const handleProcessorTypeClick = (processortype) => {
    setSelectedProcessorType(processortype);
    onProcessorTypeSelected(processortype);
  };

  return variations.length > 0 ? (
    <div
      className="sizes-container"
      style={{ display: "flex", flexDirection: "row" }}
    >
      <span
        style={{
          fontSize: "",
          fontWeight: "bold",
          color: "black",
          marginTop: "12px",
        }}
      >
        Processor Type:
      </span>
      <div className="sizes">
        {variations.map((variation, i) => (
          <div
            key={i}
            className={`size ${selectedProcessorType === variation ? "selected" : ""}`}
            onClick={() => handleProcessorTypeClick(variation)}
          >
            {variation}
          </div>
        ))}
      </div>
    </div>
  ) : (
    ""
  );
};

export default ProcessorType;

import React, { useEffect, useState, useRef } from "react";
import { NavLink, json, useParams, useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../config/connect";
import Header from "../components/Header";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import shadows from "@mui/material/styles/shadows";
import { Link } from "react-router-dom";
import Pagination from "../components/Pagination";
import MessageSkeleton from "../components/MessageSkeleton";

const styles = `
tr{
    // border: none !important;
    background-color:#f2f0f0;
    padding:20px !important;
}
tr:hover{
    box-shadow: 0px 1px 5px 1px rgba(0,0,0,0.24);
    cursor:pointer;
}
th{
    border:none !important;
    text-decoration:none !important;
}
td{
    border-top:none !important;
    border-right:none !important;
    border-left:none !important;
    border-bottom: solid 1px #ebe8e8 !important;
}

thead{
    border:none !important
}
.card{
    // box-shadow: 0px 1px 10px 5px rgba(0,0,0,0.08);
    border-radius:10px;
    border:none;
}


`;

const MyMessages = () => {
  const [messages, setMessages] = useState([]);
  const [isAnyChecked, setIsAnyChecked] = useState(true);
  const [checkCount, setCheckCount] = useState(0);
  const navigate = useNavigate();
  const [searchWord, setSearchWord] = useState("");

  const [loading, setLoading] = useState(true);

  /**
   * Pagination data starts here
   */
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(5);

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  let indexOfFirstPost = indexOfLastPost - postsPerPage;
  const slicedMessages = messages.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (data) => {
    let currentNumber = data.selected + 1;
    setCurrentPage(currentNumber);
  };
  //Pagination data ends here

  const handleCheckboxChange = (event) => {
    setIsAnyChecked(event.target.checked);
    // console.log(event.target.checked+" - ");
    if (event.target.checked) {
      setCheckCount(checkCount + 1);
    } else {
      setCheckCount(checkCount - 1);
    }
  };

  const handleRowClick = (message) => {
    // console.log("Clicked row "+message.name);
    navigate(`${message.code}`);
  };

  const getEastTime = (timestamp) => {
    var date = new Date(timestamp);
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  const getMessages = async () => {
    try {
      const response = await axios.get(
        `https://africamarkets.net/ghmarketconnect/?ghw=messages&svg=getmessages&clcode=${localStorage.getItem("username")}`
      );

      // return;

      if (response.data && response.data.length > 0) {
        const messageWithTitle = await Promise.all(
          response.data.map(async (item) => {
            const titleResponse = await axios.get(
              `https://africamarkets.net/ghmarketconnect/?ghw=messages&svg=gettitlename&trckcode=${item.trackingno}`
            );

            return {
              ...item,
              name: titleResponse.data?.name || "Unknown",
              businessname: titleResponse.data?.businessname || "Unkown",
            };
          })
        );
        setMessages(messageWithTitle);
      } else {
        console.log("No messages found");
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMessages();
  }, []);

  //search pagination
  const filteredMessages =
    searchWord.trim() === ""
      ? slicedMessages
      : messages.filter((message) =>
          message.name?.toLowerCase().startsWith(searchWord?.toLowerCase())
        );

  const pagedMessages =
    searchWord.trim() === ""
      ? messages
      : messages.filter((message) =>
          message.name?.toLowerCase().startsWith(searchWord?.toLowerCase())
        );

  //console.log('Messages:', messages);

  if (searchWord.trim() != "" && indexOfFirstPost > 0 && currentPage > 1) {
    indexOfFirstPost = 0;
  }

  return (
    <>
      <Header />
      <section>
        <div className="container pb-30 pt-30">
          <style>{styles}</style>
          <div className="row container d-flex justify-content-center">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">My Messages</h4>
                  {checkCount > 0 && (
                    <div className="d-flex justify-content-end">
                      <p className="card-description">
                        <button>Mark as Read</button>
                      </p>
                    </div>
                  )}

                  <div className="d-flex justify-content-center mb-1">
                    <div className="col-auto">
                      <input
                        type="search"
                        className="form-control rounded"
                        placeholder="Search"
                        aria-label="Search"
                        aria-describedby="search-addon"
                        onChange={(event) => setSearchWord(event.target.value)}
                      />
                    </div>
                  </div>

                  <div className="table-responsive">
                    <table className="table table-borderless">
                      <thead>
                        <tr>
                          <th>Service/Product Name</th>
                          <th>Business Name</th>
                          <th>Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <tr>
                            <td>
                              <MessageSkeleton
                                height={"50px"}
                                length={3}
                                lg={3}
                              />
                            </td>
                            <td>
                              <MessageSkeleton
                                height={"50px"}
                                length={3}
                                lg={3}
                              />
                            </td>
                            <td>
                              <MessageSkeleton
                                height={"50px"}
                                length={3}
                                lg={3}
                              />
                            </td>
                          </tr>
                        ) : (
                          filteredMessages.map((message) => (
                            <tr
                              key={message.id}
                              onClick={() => handleRowClick(message)}
                              style={{
                                backgroundColor:
                                  message.status == "1" ? "white" : "#f2f0f0",
                              }}
                            >
                              <td> {message.name}</td>
                              <td>{message.businessname}</td>
                              <td>{getEastTime(message.datesent)}</td>
                            </tr>
                          ))
                        )}

                        {filteredMessages.length > 0 ? (
                          <tr>
                            <td>
                              Showing {indexOfFirstPost + 1} to{" "}
                              {indexOfLastPost > pagedMessages.length
                                ? pagedMessages.length
                                : indexOfLastPost}{" "}
                              of {pagedMessages.length} Message(s)
                            </td>
                            <td></td>
                            <td>
                              {" "}
                              <Pagination
                                postsPerPage={postsPerPage}
                                totalPosts={pagedMessages.length}
                                paginate={paginate}
                              />
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MyMessages;

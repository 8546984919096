import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { API_BASE_URL } from "../../config/connect";
import SizeGuideModal from "../SizeGuideModal";

const Sizes = ({ initialValue, onSelected }) => {
  const [selectedSize, setSelectedSize] = useState(initialValue || null); // Initialize with initialValue prop
  const [variations, setVariations] = useState([]); // Store the list of sizes
  const { prodcode } = useParams(); // Extract product code from URL params
  const [isSizeGuideOpen, setIsSizeGuideOpen] = useState(false); // Manage size guide modal state

  // Open size guide modal
  const handleOpenSizeGuide = () => {
    setIsSizeGuideOpen(true);
  };

  // Close size guide modal
  const handleCloseSizeGuide = () => {
    setIsSizeGuideOpen(false);
  };

  // Fetch product size variations
  const getAllProductVariations = async () => {
    try {
      const response = await fetch(
        `https://africamarkets.net/ghmarketconnect/?ghw=productservices&svg=fashionvariations&prcode=${prodcode}`
      );
      const json = await response.json();
      const firstResult = json["results"][0];
      if (firstResult && firstResult.size) {
        const sizeObject = firstResult.size;
        if (sizeObject.trim() !== "") {
          setVariations(sizeObject.split(","));
        } else {
          setVariations([]);
        }
      }
    } catch (error) {
      console.error("Error fetching product variations:", error);
      setVariations([]);
    }
  };

  // Fetch product size variations when the product code changes
  useEffect(() => {
    getAllProductVariations();
  }, [prodcode]);

  // Update state when initialValue changes
  useEffect(() => {
    if (initialValue) {
      setSelectedSize(initialValue);
    }
  }, [initialValue]);

  // Handle size selection toggle
  const handleSizeClick = (size) => {
    const newSelectedSize = selectedSize === size ? null : size;
    setSelectedSize(newSelectedSize);
    onSelected(newSelectedSize); // Notify parent component
  };

  return variations.length > 0 ? (
    <div
      className="sizes-container"
      style={{ display: "flex", flexDirection: "row" }}
    >
      <span
        className="title"
        style={{
          fontWeight: "bold",
          color: "black",
          marginTop: "12px",
        }}
      >
        Sizes:
      </span>
      <div className="sizes" style={{ display: "flex", gap: "8px" }}>
        {variations.map((variation, i) => (
          <div
            key={i}
            className={`size ${selectedSize === variation ? "selected" : ""}`}
            style={{
              padding: "8px 12px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              cursor: "pointer",
              backgroundColor:
                selectedSize === variation ? "#FF4800" : "#f9f9f9",
              color: selectedSize === variation ? "#fff" : "#000",
            }}
            onClick={() => handleSizeClick(variation)}
          >
            {variation}
          </div>
        ))}
      </div>
      <div style={{ marginTop: "12px", marginLeft: "15px" }}>
        <NavLink className="size-guide" onClick={handleOpenSizeGuide}>
          Size Guide
        </NavLink>
      </div>
      <SizeGuideModal
        isOpen={isSizeGuideOpen}
        onRequestClose={handleCloseSizeGuide}
      />
    </div>
  ) : (
    ""
  );
};

export default Sizes;

import React from "react";
import { ToastContainer } from "react-toastify";
import Header from "../components/Header";

const Returns = () => {
  return (
    <>
      <Header />
      <section className="return-policy-section py-5">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-10 col-sm-12">
              <div className="card shadow p-4">
                <div className="card-header text-white text-center">
                  <h2 className="mb-0">Return Policy</h2>
                </div>
                <div className="card-body">
                  <h6>Eligibility for Returns:</h6>
                  <p>
                    <strong>1. Time Frame:</strong> <br />
                    - You may initiate a return within 10 days from the date of
                    delivery. <br />- Returns requested after 10 days will not
                    be accepted.
                  </p>
                  <p>
                    <strong>2. Condition of the Item:</strong> <br />
                    - Items must be unused, undamaged, and in their original
                    packaging. <br />- Clothing and accessories must have all
                    original tags attached.
                  </p>

                  <h6>How to Initiate a Return:</h6>
                  <p>
                    <strong>1. Contact Customer Service:</strong> <br />
                    - To initiate a return, please contact our customer service
                    team within the specified return period. <br />- Provide
                    your order number, the reason for the return, and any
                    relevant details.
                  </p>
                  <p>
                    <strong>2. Return Authorization:</strong> <br />
                    - Once your return request is approved, you will receive a
                    Return Authorization (RA) number. <br />- Clearly mark the
                    RA number on the outside of the package.
                  </p>

                  <h6>Returning the Item:</h6>
                  <p>
                    <strong>1. Packaging:</strong> <br />- Securely pack the
                    item, ensuring it is well-protected to prevent damage during
                    transit.
                  </p>

                  <h6>Refund Process:</h6>
                  <p>
                    <strong>1. Inspection:</strong> <br />- Upon receiving the
                    return, our team will inspect the item for eligibility.
                  </p>
                  <p>
                    <strong>2. Refund Issuance:</strong> <br />
                    - If the return is approved, a refund will be issued to the
                    original payment method. <br />- Please allow 3 business
                    days for the refund to reflect in your account.
                  </p>

                  <h6>Exceptions:</h6>
                  <p>
                    <strong>1. Non-returnable Items:</strong> <br />- Some items
                    are not eligible for return.
                  </p>
                  <p>
                    <strong>2. Final Sale Items:</strong> <br />- Items marked
                    as final sale cannot be returned or exchanged.
                  </p>

                  <h6>Contact Us:</h6>
                  <p>
                    If you have any questions or concerns about our return
                    policy, please contact our customer service team.
                  </p>
                  <p>
                    Africa Markets reserves the right to update or modify this
                    return policy at any time without prior notice. Please check
                    our website for the most up-to-date information.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  );
};

export default Returns;

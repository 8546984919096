import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../config/connect";

const Amplification = ({ initialValue, onSelected }) => {
  const [selectedAmplification, setSelectedAmplification] = useState(
    initialValue || null
  ); // Initialize with initialValue
  const [variations, setVariations] = useState([]); // Store amplification variations
  const { prodcode } = useParams(); // Extract product code from URL params

  // Fetch product amplification variations
  const getAllProductVariations = async () => {
    try {
      const response = await fetch(
        `https://africamarkets.net/ghmarketconnect/?ghw=productservices&svg=fashionvariations&prcode=${prodcode}`
      );
      const json = await response.json();
      const firstResult = json["results"][0];
      if (firstResult && firstResult.amplification) {
        const amplificationObject = firstResult.amplification;
        if (amplificationObject.trim() !== "") {
          setVariations(amplificationObject.split(","));
        } else {
          setVariations([]); // If empty, set empty list
        }
      }
    } catch (error) {
      console.error("Error fetching variations:", error); // Log the error for debugging
      setVariations([]); // Handle the error gracefully by setting empty variations
    }
  };

  // Update state when initialValue changes
  useEffect(() => {
    if (initialValue) {
      setSelectedAmplification(initialValue);
    }
  }, [initialValue]);

  // Trigger when an amplification variation is selected
  const handleAmplificationClick = (amplification) => {
    const newSelected =
      selectedAmplification === amplification ? null : amplification;
    setSelectedAmplification(newSelected); // Update the selected amplification state
    onSelected(newSelected); // Notify the parent with the selected value
  };

  return variations.length > 0 ? (
    <div
      className="sizes-container"
      style={{ display: "flex", flexDirection: "row" }}
    >
      <span style={{ fontWeight: "bold", color: "black", marginTop: "12px" }}>
        Amplification:
      </span>
      <div className="sizes" style={{ display: "flex", gap: "8px" }}>
        {variations.map((variation, i) => (
          <div
            key={i}
            className={`size ${selectedAmplification === variation ? "selected" : ""}`}
            style={{
              padding: "8px 12px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              cursor: "pointer",
              backgroundColor:
                selectedAmplification === variation ? "#FF4800" : "#f9f9f9",
              color: selectedAmplification === variation ? "#fff" : "#000",
            }}
            onClick={() => handleAmplificationClick(variation)}
          >
            {variation}
          </div>
        ))}
      </div>
    </div>
  ) : null; // Return null if no variations available
};

export default Amplification;

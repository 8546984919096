import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { API_BASE_URL } from "../../config/connect";
import SizeGuideModal from "../SizeGuideModal";

const Variations = ({ type = "Material", initialValue, onSelected }) => {
  const [selectedVariation, setSelectedVariation] = useState(
    initialValue || ""
  );
  const [variations, setVariations] = useState([]);
  const { prodcode } = useParams();
  const [isSizeGuideOpen, setIsSizeGuideOpen] = useState(false); // Manage size guide modal state

  // Open size guide modal
  const handleOpenSizeGuide = () => {
    setIsSizeGuideOpen(true);
  };

  // Close size guide modal
  const handleCloseSizeGuide = () => {
    setIsSizeGuideOpen(false);
  };

  // Update state when initialValue changes
  useEffect(() => {
    if (initialValue) {
      setSelectedVariation(initialValue);
    }
  }, [initialValue]);

  // Fetch and filter variations dynamically based on type
  const fetchVariations = async () => {
    try {
      const response = await fetch(
        `https://africamarkets.net/ghmarketconnect/?ghw=productservices&svg=fashionvariations&prcode=${prodcode}`
      );
      const json = await response.json();
      const firstResult = json?.results?.[0];

      if (firstResult?.[type]?.trim()) {
        // Filter out empty or invalid variations
        const validVariations = firstResult[type]
          .split(",")
          .map((v) => v.trim())
          .filter((v) => v);
        setVariations(validVariations);
      } else {
        setVariations([]);
      }
    } catch (error) {
      console.error(`Error fetching ${type} variations:`, error);
      setVariations([]);
    }
  };

  useEffect(() => {
    fetchVariations();
  }, [prodcode, type]);

  // Handle variation selection toggle
  const handleVariationClick = (variation) => {
    const newValue = selectedVariation === variation ? "" : variation;
    setSelectedVariation(newValue);
    onSelected(newValue);
  };

  // Determine if there's a variation not in the size list
  const hasNonStandardSizes = () => {
    const standardSizes = ["Medium", "Big", "Small", "Large", "Extra Large"];
    return variations.some((variation) => !standardSizes.includes(variation));
  };

  return variations.length > 0 ? (
    <div
      className="variations-container"
      style={{
        display: "flex",
        flexDirection: "row",
        marginBottom: "16px",
      }}
    >
      <h6
        style={{
          fontWeight: 400,
          fontSize: "15px",
          marginRight: "8px",
          marginTop: "10px",
        }}
      >
        {type}:
      </h6>
      <div className="variations" style={{ display: "flex", gap: "8px" }}>
        {variations.map((variation, index) => (
          <div key={index} style={{ display: "flex" }}>
            <div
              className={`variation ${
                selectedVariation === variation ? "selected" : ""
              }`}
              style={{
                padding: "8px 12px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                cursor: "pointer",
                backgroundColor:
                  selectedVariation === variation ? "#FF4800" : "#f9f9f9",
                color: selectedVariation === variation ? "#fff" : "#000",
              }}
              onClick={() => handleVariationClick(variation)}
            >
              {variation}
            </div>
          </div>
        ))}
        {/* Display "Size Guide" link if there is a non-standard size */}
        {type === "size" && hasNonStandardSizes() && (
          <div style={{ marginTop: "12px", marginLeft: "15px" }}>
            <NavLink
              className="size-guide"
              onClick={handleOpenSizeGuide}
              style={{
                cursor: "pointer",
                color: "#007bff",
                textDecoration: "none",
              }}
            >
              Size Guide
            </NavLink>
            <SizeGuideModal
              isOpen={isSizeGuideOpen}
              onRequestClose={handleCloseSizeGuide}
            />
          </div>
        )}
      </div>
    </div>
  ) : null; // Render nothing if there are no valid variations
};

export default Variations;

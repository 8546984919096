import React, { useState } from "react";
import styles from "./ModalImages.module.css";

/* TO USE THIS COMPONENT PASS IN YOUR IMAGE(S) AND IMAGE CONTAINER AS A PROP */
const ModalSlider = ({ productcode, ImgComponent }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [images, setImages] = useState([]);

  const getImages = (images) => {
    setImages(images);
  };

  const openModal = (index) => {
    setCurrentIndex(index);
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  const changeImage = (direction) => {
    setCurrentIndex(
      (prevIndex) => (prevIndex + direction + images.length) % images.length
    );
  };
  return (
    <>
      <ImgComponent
        productcode={productcode}
        openModal={openModal}
        getImages={getImages}
      />
      {isOpen && (
        <div className={`${styles["modal"]} ${styles["fade-in"]}`}>
          <button className={styles["close"]} onClick={closeModal}>
            &times;
          </button>
          {images.length > 1 ? (
            <button className={styles["prev"]} onClick={() => changeImage(-1)}>
              &#10094;
            </button>
          ) : (
            ""
          )}

          <div
            className={styles["modal-content"]}
            onClick={() => changeImage(1)}
          >
            <img
              src={images[currentIndex].image}
              alt="Modal"
              className={styles["slide-animation"]}
            />
          </div>
          {images.length > 1 ? (
            <button className={styles["next"]} onClick={() => changeImage(1)}>
              &#10095;
            </button>
          ) : (
            ""
          )}
        </div>
      )}
    </>
  );
};

export default ModalSlider;

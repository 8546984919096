import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// import money from "../assets/img/payment/money.png";
import momo from "../assets/img/payment/momo.webp";
import vodacash from "../assets/img/payment/vodacash.png";
import atmoney from "../assets/img/payment/atmoney.png";
import cards from "../assets/img/payment/cards.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { API_BASE_URL } from "../config/connect";
import CheckoutPaySummary from "../components/CheckoutPaySummary";
import ServicePaySummary from "../components/ServicePaySummary";

const CheckoutServicePayment = () => {
  const email = localStorage.getItem(btoa("scdi"));
  // console.log("Customer Email =>" + email);
  const paymentoption = localStorage.getItem(btoa("spid"));
  // console.log("Payment option " + paymentoption);

  const track = localStorage.getItem(btoa("tckcd"));

  const [result, setResult] = useState("");
  const [mobilemoneycontact, setMobileMoneyContact] = useState("");
  const [paymentdata, setPaymentData] = useState({
    payment_method: "",
    payment_option: "",
    // mobile_money_contact: mobilemoneycontact,
  });
  const [toSummary, setToSummary] = useState({});

  const [toggleDisplay, setToggleDisplay] = useState("none");
  const navigate = useNavigate();

  const getQuote = async () => {
    if (!track) {
      // console.log("Track code is null");
    } else {
      // console.log("track code is set " + track);
    }
    const resquote = await fetch(
      `https://africamarkets.net/ghmarketconnect/?ghw=requestservice&svg=detquote`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ track_code: track }),
      }
    );

    const responsequote = await resquote.json();
    return responsequote;
  };

  // const cartProducts = useSelector((state) => state.cart);
  // const cartItems = JSON.parse(localStorage.getItem("cart"));

  // const getCartProducts =
  //   cartProducts?.products?.length > 0 ? cartProducts : cartItems;

  // const showCheckoutBtn =
  //   getCartProducts?.products?.length < 1 ? "disabled" : "";

  // setToggleDisplay(toggleDisplay);

  const handleInput = (event) => {
    const { name, value } = event.target;
    const data_name = event.target.attributes.payment_method.name;
    const data_value = event.target.attributes.payment_method.value;

    setPaymentData((data) => ({
      ...data,
      [name]: value,
      [data_name]: data_value,
    }));

    event.target.value === "momo" ||
    event.target.value === "vodacash" ||
    event.target.value === "atmoney"
      ? setToggleDisplay("block")
      : setToggleDisplay("none");
    setMobileMoneyContact("");
  };

  useEffect(() => {
    const quote = getQuote();

    quote.then((js) => {
      // console.log("The quote coming in ... " + js["quote"]);
      setToSummary(js["quote"]);
    });
  }, []);

  const notify = (mode, msg) => (mode !== "" && msg ? toast[mode](msg) : "");

  const handlePaymentDetail = async () => {
    // navigate("/checkout/summary/");
    const email = localStorage.getItem(btoa("scdi"));

    const res = await fetch(
      `https://africamarkets.net/ghmarketconnect/?ghw=processpayment&svg=paydetails`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...paymentdata,
          cdi: email,
          mobile_money_contact: mobilemoneycontact,
        }),
      }
    );
    const response = await res.json();

    setResult(response.data);

    if (response) {
      let mode = result["error"] === "1" ? "success" : "error";
      notify(mode, result["msg"]);
      const dlres = response.data;
      // console.log("Response on saving payment.." +Object.values(dlres));
      // save client info to localstorage
      localStorage.setItem(btoa("spid"), JSON.stringify(response.data));

      // unset user input
      setPaymentData({
        payment_option: "",
        payment_method: "",
      });

      const paymentoption = localStorage.getItem(btoa("spid"));

      //   redirect
      paymentoption === null || paymentoption === ""
        ? navigate("/checkout/servicepayment")
        : navigate("/checkout/servicesummary");
    }
  };

  return (
    <>
      <Header />

      <section className="coupon-product-area pt-30 pb-60">
        <div className="container pb-30">
          <div className="row mb-1">
            <NavLink onClick={() => navigate("/")}>
              <span>
                <i className="fa fa-arrow-left"></i> Go back & continue shopping
              </span>
            </NavLink>
          </div>
          <div className="row">
            <div className="col-sm-9 mb-20">
              <div className="card">
                <div className="card-header">Payment method</div>
                <div className="card-body">
                  {/* <h5 className="card-title">Add Details</h5> */}
                  {/* <PaymentCard /> */}

                  <p className="text-capitalize mt-2">
                    Select a payment method to proceed
                  </p>

                  <div className="d-flex flex-wrap gap-2 align-items-center mb-4 ">
                    <h5 className="mb-0 text-capitalize">Pay via Cards</h5>
                    <span className="fs-10 text-capitalize mt-1">
                      (Visa &amp; Mastercard)
                    </span>
                  </div>

                  <div className="d-flex flex-wrap gap-3 mb-5">
                    <div id="cod-for-cart">
                      <div className="card cursor-pointer">
                        <label className="m-0">
                          <input
                            type="hidden"
                            name="payment_method"
                            value="cards_payment"
                          />
                          <span className="btn-plain btn-block click-if-alone d-flex gap-2 align-items-center cursor-pointer">
                            <input
                              type="radio"
                              id="cards_payment"
                              className="custom-radio"
                              name="payment_option"
                              value="card"
                              payment_method="card"
                              onChange={(event) => handleInput(event)}
                            />
                            <img width="50" src={cards} alt="" />
                            <span className="fs-12">Cards</span>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex flex-wrap gap-2 align-items-center mb-4 ">
                    <h5 className="mb-0 text-capitalize">
                      Pay via mobile money
                    </h5>
                    <span className="fs-10 text-capitalize mt-1">
                      (Faster &amp; secure way to pay bill)
                    </span>
                  </div>

                  <div className="row gx-4 mb-4">
                    <div className="col-sm-6">
                      <input
                        type="hidden"
                        name="payment_method"
                        value="mobilemoney"
                      />
                      <label className="d-flex align-items-center gap-2 mb-0 form-check py-2 cursor-pointer">
                        <input
                          type="radio"
                          id="mobilemoney"
                          name="payment_option"
                          className="form-check-input custom-radio"
                          value="momo"
                          payment_method="mobile money"
                          onChange={(event) => handleInput(event)}
                        />
                        <img width="30" src={momo} alt="" />
                        <span className="text-capitalize form-check-label">
                          MTN mobile money
                        </span>
                      </label>
                    </div>
                    <div className="col-sm-6">
                      <input
                        type="hidden"
                        name="payment_method"
                        value="mobilemoney"
                      />

                      <label className="d-flex align-items-center gap-2 mb-0 form-check py-2 cursor-pointer">
                        <input
                          type="radio"
                          id="mobilemoney"
                          name="payment_option"
                          className="form-check-input custom-radio"
                          value="vodacash"
                          payment_method="mobile money"
                          onChange={(event) => handleInput(event)}
                        />
                        <img width="30" src={vodacash} alt="" />
                        <span className="text-capitalize form-check-label">
                          Vodacash
                        </span>
                      </label>
                    </div>
                    <div className="col-sm-6">
                      <input
                        type="hidden"
                        name="payment_method"
                        value="mobilemoney"
                      />

                      <label className="d-flex align-items-center gap-2 mb-0 form-check py-2 cursor-pointer">
                        <input
                          type="radio"
                          id="mobilemoney"
                          name="payment_option"
                          className="form-check-input custom-radio"
                          value="atmoney"
                          payment_method="mobile money"
                          onChange={(event) => handleInput(event)}
                        />
                        <img width="50" src={atmoney} alt="" />
                        <span className="text-capitalize form-check-label">
                          ATMoney
                        </span>
                      </label>
                    </div>
                  </div>
                  <div
                    className="mobile-money-toggle"
                    style={{ display: toggleDisplay }}
                  >
                    <hr />
                    <div className="col-sm-6">
                      <label htmlFor="mobile_money_contact">
                        Mobile Money Contact
                      </label>
                      <input
                        type="text"
                        id="mobile_money_contact"
                        name="mobile_money_contact"
                        className="form-control"
                        value={mobilemoneycontact}
                        placeholder="0200123456"
                        onChange={(event) =>
                          setMobileMoneyContact(event.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="card-footer d-flex justify-content-end gap-15">
                  <button
                    type="submit"
                    className="btn btn-outline-primary"
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={() => handlePaymentDetail()}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>

            <ServicePaySummary />
          </div>
        </div>
      </section>

      <ToastContainer />
    </>
  );
};

export default CheckoutServicePayment;

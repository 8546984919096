import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../config/connect";

const SensorType = ({ onSensorTypeSelected }) => {
  const [selectedSensorType, setSelectedSensorType] = useState(null);
  const [variations, setVariations] = useState([]);
  const { prodcode } = useParams();

  const getAllProductVariations = async () => {
    try {
      const response = await fetch(
        `https://africamarkets.net/ghmarketconnect/?ghw=productservices&svg=fashionvariations&prcode=${prodcode}`
      );
      const json = await response.json();
      const firstResult = json["results"][0];
      if (firstResult && firstResult.sensortype) {
        const sensorTypeObject = firstResult.sensortype;
        if (sensorTypeObject.trim() !== "") {
          setVariations(sensorTypeObject.split(","));
        }
      }
    } catch (error) {
      // Handle error here
    }
  };

  useEffect(() => {
    getAllProductVariations();
  }, [prodcode]);

  const handleSensorTypeClick = (sensortype) => {
    setSelectedSensorType(sensortype);
    onSensorTypeSelected(sensortype);
  };

  return variations.length > 0 ? (
    <div
      className="sizes-container"
      style={{ display: "flex", flexDirection: "row" }}
    >
      <span
        style={{
          fontSize: "",
          fontWeight: "bold",
          color: "black",
          marginTop: "12px",
        }}
      >
        Sensor Type:
      </span>
      <div className="sizes">
        {variations.map((variation, i) => (
          <div
            key={i}
            className={`size ${selectedSensorType === variation ? "selected" : ""}`}
            onClick={() => handleSensorTypeClick(variation)}
          >
            {variation}
          </div>
        ))}
      </div>
    </div>
  ) : (
    ""
  );
};

export default SensorType;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../config/connect";

const ActivityType = ({ initialValue, onSelected }) => {
  const [selectedActivityType, setSelectedActivityType] = useState(
    initialValue || null
  ); // Initialize with initialValue
  const [variations, setVariations] = useState([]); // Store variations of activity types
  const { prodcode } = useParams(); // Extract product code from URL params

  // Fetch product activity type variations
  const getAllProductVariations = async () => {
    try {
      const response = await fetch(
        `https://africamarkets.net/ghmarketconnect/?ghw=productservices&svg=fashionvariations&prcode=${prodcode}`
      );
      const json = await response.json();
      const firstResult = json["results"][0];
      if (firstResult && firstResult.activitytype) {
        const activityTypeObject = firstResult.activitytype;
        if (activityTypeObject.trim() !== "") {
          setVariations(activityTypeObject.split(","));
        } else {
          setVariations([]);
        }
      }
    } catch (error) {
      console.error("Error fetching variations:", error); // Log error for debugging
      setVariations([]);
    }
  };

  useEffect(() => {
    getAllProductVariations();
  }, [prodcode]);

  // Update state when initialValue changes
  useEffect(() => {
    if (initialValue) {
      setSelectedActivityType(initialValue);
    }
  }, [initialValue]);

  // Handle activity type selection toggle
  const handleActivityTypeClick = (activitytype) => {
    const newSelected =
      selectedActivityType === activitytype ? null : activitytype;
    setSelectedActivityType(newSelected);
    onSelected(newSelected); // Notify parent component
  };

  return variations.length > 0 ? (
    <div
      className="sizes-container"
      style={{ display: "flex", flexDirection: "row" }}
    >
      <span style={{ fontWeight: "bold", color: "black", marginTop: "12px" }}>
        Activity Type:
      </span>
      <div className="sizes" style={{ display: "flex", gap: "8px" }}>
        {variations.map((variation, i) => (
          <div
            key={i}
            className={`size ${selectedActivityType === variation ? "selected" : ""}`}
            style={{
              padding: "8px 12px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              cursor: "pointer",
              backgroundColor:
                selectedActivityType === variation ? "#FF4800" : "#f9f9f9",
              color: selectedActivityType === variation ? "#fff" : "#000",
            }}
            onClick={() => handleActivityTypeClick(variation)}
          >
            {variation}
          </div>
        ))}
      </div>
    </div>
  ) : null; // Return null if no variations available
};

export default ActivityType;

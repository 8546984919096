import React, {
  useState,
  useContext,
  useCallback,
  useMemo,
  useEffect,
} from "react";
import { API_BASE_URL } from "../config/connect";

import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import useFetch from "../hooks/useFetch";

// import Leftmenu from "../components/Leftmenu";
// import Navbar from "../components/Navbar";
// import sidebar_product01 from "../assets/img/product/sidebar_product01.jpg";
// import sidebar_product02 from "../assets/img/product/sidebar_product02.jpg";
// import sidebar_product03 from "../assets/img/product/sidebar_product03.jpg";
// import Topbar from "../components/Topbar";
// import Livemarket from "../components/Livemarket";
// import Topsearch from "../components/Topsearch";
import Nodata from "../components/Nodata";
import Pagination from "../components/Pagination";
import Trendingproducts from "../components/Trendingproducts";
import RatingPerItem from "../components/RatingPerItem";
import Header from "../components/Header";
import { useProductData } from "../components/ProductsContextApi";
import Filtering from "../components/Filtering";

const RelatedProductsPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchTerm = searchParams.get("search") || "";

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [nodata, setNodata] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(9);

  //console.log(searchTerm)
  useEffect(() => {
    const getSearch = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `https://africamarkets.net/ghmarketconnect/?ghw=productservices&svg=allproduct&searchword=${searchTerm}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const json = await response.json();
        setData(json["results"]);
        setNodata(json["results"].length === 0); // Update no data state based on response
      } catch (error) {
        // console.error('Error fetching data:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    getSearch();
  }, [searchTerm]);

  // const allprods = useProductData().allProductData;
  //const alls =  useMemo(() => data?.filter((product) => product.name.toLowerCase().includes(searchTerm.toLowerCase())), [data]);
  const [filteredProducts, setFilteredProducts] = useState(data);

  // console.log(data)

  // Function to handle filtering when the button is clicked
  const applyFilter = useCallback(
    (searchText, minPrice, maxPrice) => {
      const filtered = data?.filter((product) => {
        const productName = product.name?.toLowerCase() || "";
        const productDescription = product.description?.toLowerCase() || "";
        const productModel = product.model?.toLowerCase() || "";
        const productCategoryName = product.categoryname?.toLowerCase() || "";
        const productSubCategoryName =
          product.subcategoryname?.toLowerCase() || "";
        const productBrandName = product.brandname?.toLowerCase() || "";

        const searchTextMatch =
          productName.includes(searchText.toLowerCase()) ||
          productDescription.includes(searchText.toLowerCase()) ||
          productModel.includes(searchText.toLowerCase()) ||
          productCategoryName.includes(searchText.toLowerCase()) ||
          productSubCategoryName.includes(searchText.toLowerCase()) ||
          productBrandName.includes(searchText.toLowerCase());

        let prodPrice = product.price == null ? 0 : product.price;
        minPrice = isNaN(minPrice) ? 0 : minPrice;

        const priceMatch =
          (!minPrice || parseFloat(prodPrice) >= parseFloat(minPrice)) &&
          (!maxPrice || parseFloat(prodPrice) <= parseFloat(maxPrice));

        return searchTextMatch && priceMatch;
      });

      setFilteredProducts(filtered);
      setCurrentPage(1); // Reset to first page after filtering
    },
    [data]
  );

  // const [alls, setAllproduct] = useState("");

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = useMemo(
    () => filteredProducts?.slice(indexOfFirstPost, indexOfLastPost),
    [filteredProducts, indexOfFirstPost, indexOfLastPost]
  );

  // const getAllProduct = useCallback(() => {
  //   fetch(
  //     `https://africamarkets.net/ghmarketconnect/?ghw=productservices&svg=allproduct&search=${searchTerm}`
  //   )
  //     .then((response) => response.json())
  //     .then((json) => {
  //       // console.log(json["results"]);
  //       if (json["results"].length > 0) {
  //         setAllproduct(json["results"]);
  //         setNodata(false);
  //       } else {
  //         setAllproduct([]);
  //         setNodata(true);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, [searchTerm]);

  // useEffect(() => {
  //   // Fetch related products based on the searchTerm
  //   // You can make an API call or use any logic to get related products
  //   getAllProduct();
  //   // console.log(`Fetching related products for: ${searchTerm}`);
  // }, [getAllProduct]);

  // console.log(alls.length);

  const paginate = (data) => {
    let currentNumber = data.selected + 1;
    setCurrentPage(currentNumber);
  };

  return (
    <div>
      <header>
        <Header />
        {/* 
        <Topbar />
        <Topsearch />
         <div className="header-search-area style-two">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-2 col-lg-3">
                <div className="logo">
                  <NavLink to="/">
                    <img src="assets/img/logo/w_logo.png" alt="" />
                  </NavLink>
                </div>
              </div>

            
            </div>
          </div>
        </div> 
        <div id="sticky-header" className="menu-area style-two">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="mobile-nav-toggler">
                  <i className="fas fa-bars"></i>
                </div>
                <div className="menu-wrap">
                  <nav className="menu-nav">
                    <div className="logo d-none">
                      <NavLink to="index-2.html">
                        <img src="assets/img/logo/w_logo.png" alt="" />
                      </NavLink>
                    </div>
                    <Leftmenu />
                    <Navbar />
                    <Livemarket />
                  </nav>
                </div>

                <div className="mobile-menu">
                  <nav className="menu-box">
                    <div className="close-btn">
                      <i className="fa-solid fa-xmark"></i>
                    </div>
                    <div className="nav-logo">
                      <NavLink to="index-2.html">
                        <img src="assets/img/logo/logo.png" alt="" title="" />
                      </NavLink>
                    </div>
                    <div className="menu-outer"></div>
                    <div className="social-links">
                      <ul className="clearfix">
                        <li>
                          <NavLink to="#">
                            <span className="fab fa-twitter"></span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="#">
                            <span className="fab fa-facebook-f"></span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="#">
                            <span className="fab fa-pinterest-p"></span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="#">
                            <span className="fab fa-instagram"></span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="#">
                            <span className="fab fa-youtube"></span>
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
                <div className="menu-backdrop"></div>
              </div>
            </div>
          </div>
        </div> */}
      </header>

      <main>
        <div className="shop-area pt-90 pb-90">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-3 col-lg-4 col-md-8 col-sm-10 order-2 order-lg-0">
                <aside className="shop-sidebar">
                  {/* <div className="widget mb-40">
                    <div className="widget-title mb-25"> */}
                  <Filtering
                    onFilter={applyFilter} // Pass the applyFilter function
                    data={data} // Pass the products data to the Filtering component
                  />
                  <Trendingproducts>Service Providers</Trendingproducts>
                  {/* </div> */}
                  {/* <div className="sidebar-product-list">
                      <ul>
                        <li>
                          <div className="thumb">
                            <NavLink to="">
                              <img src={sidebar_product01} alt="img" />
                            </NavLink>
                          </div>
                          <div className="content">
                            <h6 className="title">
                              <NavLink to="shop-details.html">
                                Appliance Aid Kitchen Stand
                              </NavLink>
                            </h6>
                            <div className="rating">
                              <i className="fa-solid fa-star"></i>
                              <i className="fa-solid fa-star"></i>
                              <i className="fa-solid fa-star"></i>
                              <i className="fa-solid fa-star"></i>
                              <i className="fa-solid fa-star"></i>
                            </div>
                            <h4 className="price">
                              <del>$39.08</del> $19.00
                            </h4>
                          </div>
                        </li>
                        <li>
                          <div className="thumb">
                            <NavLink to="shop-details.html">
                              <img src={sidebar_product02} alt="img" />
                            </NavLink>
                          </div>
                          <div className="content">
                            <h6 className="title">
                              <NavLink to="">One Washer Dryer</NavLink>
                            </h6>
                            <div className="rating">
                              <i className="fa-solid fa-star"></i>
                              <i className="fa-solid fa-star"></i>
                              <i className="fa-solid fa-star"></i>
                              <i className="fa-solid fa-star"></i>
                              <i className="fa-solid fa-star"></i>
                            </div>
                            <h4 className="price">
                              <del>$39.08</del> $19.00
                            </h4>
                          </div>
                        </li>
                        <li>
                          <div className="thumb">
                            <NavLink to="shop-details.html">
                              <img src={sidebar_product03} alt="img" />
                            </NavLink>
                          </div>
                          <div className="content">
                            <h6 className="title">
                              <NavLink to="">Electric Blender Mixer</NavLink>
                            </h6>
                            <div className="rating">
                              <i className="fa-solid fa-star"></i>
                              <i className="fa-solid fa-star"></i>
                              <i className="fa-solid fa-star"></i>
                              <i className="fa-solid fa-star"></i>
                              <i className="fa-solid fa-star"></i>
                            </div>
                            <h4 className="price">
                              <del>$39.08</del> $19.00
                            </h4>
                          </div>
                        </li>
                      </ul>
                    </div> */}
                  {/* </div> */}
                </aside>
              </div>
              <div className="col-xl-9 col-lg-8">
                <div className="shop-top-wrap mb-35">
                  <div className="shop-top-left">
                    <h5 className="title">
                      {" "}
                      Search Results For : {searchTerm}
                    </h5>
                  </div>
                  <div className="shop-top-right">
                    <form action="#">
                      <label htmlFor="shortBy">sort By</label>
                      {/* <select id="shortBy" name="select" className="form-select" aria-label="Default select example">
                                            <option value="">Sorting</option>
                                            <option>Free Shipping</option>
                                            <option>Best Match</option>
                                            <option>Newest Item</option>
                                            <option>Size A - Z</option>
                                        </select> */}
                    </form>
                    <ul>
                      {/* <li>View</li>
                      <li className="active">
                        <NavLink to="">
                          <i className="fa-solid fa-table-cells"></i>
                        </NavLink>
                      </li> */}
                      <li>
                        <NavLink to="">
                          <i className="fa-solid fa-bars"></i>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="row justify-content-center">
                  {!nodata ? (
                    currentPosts &&
                    currentPosts.map((currentPost, index) => {
                      return (
                        <div
                          className="col-xl-4 col-lg-6 col-md-6 col-sm-8"
                          key={index}
                        >
                          <div className="shop-product-item mb-30">
                            <div className="shop-thumb">
                              <NavLink to={`shopdetail/${currentPost.code}`}>
                                <div style={{ position: "relative" }}>
                                  <img
                                    src={
                                      currentPost.previewpicture ||
                                      currentPost.picture
                                    }
                                    alt="img"
                                    style={{
                                      height: "200px",
                                      objectFit: "cover",
                                    }}
                                  />
                                  {currentPost.freedelivery === "1" && (
                                    <div
                                      className="mo"
                                      style={{
                                        position: "absolute",
                                        top: "3px",
                                        right: "35px", // Set this to adjust position based on your preference
                                        backgroundColor: "#F6921E",
                                        color: "white",
                                        padding: "1px 10px",
                                        fontSize: "12px",
                                        borderRadius: "5px",
                                        height: "20px",
                                        display: "flex",
                                        alignItems: "center", // Centers the text vertically
                                      }}
                                    >
                                      Free Delivery
                                    </div>
                                  )}
                                </div>
                              </NavLink>
                              {/* <span>New</span> */}
                            </div>
                            <div className="shop-content">
                              <ul className="tag">
                                <li>
                                  Sold by{" "}
                                  <NavLink
                                    to={`/shopitems/${currentPost.sellercode}`}
                                  >
                                    {currentPost.sellername}
                                  </NavLink>
                                </li>
                              </ul>
                              <h2 className="title">
                                <NavLink to={`shopdetail/${currentPost.code}`}>
                                  {currentPost.name}
                                </NavLink>
                              </h2>
                              <div className="rating d-flex">
                                <div>
                                  {/* Generate 5 star icons with yellow color for the rated stars */}
                                  {Array.from({ length: 5 }, (_, index) => (
                                    <i
                                      key={index}
                                      className="fa-solid fa-star"
                                      style={{
                                        color:
                                          index <
                                          Math.floor(currentPost.ratingaverage)
                                            ? "#ffc107"
                                            : "#e4e5e9",
                                      }}
                                    ></i>
                                  ))}
                                </div>
                                <div>
                                  <h6
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "12px",
                                      marginLeft: "3px",
                                      marginTop: "10px",
                                    }}
                                  >
                                    ({0})
                                  </h6>
                                </div>
                              </div>
                              {/* <span>Already Sold : 75%</span>
                              <div className="progress">
                                <div
                                  className="progress-bar w-75"
                                  role="progressbar"
                                  aria-valuenow="75"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                ></div>
                              </div> */}
                              <div className="content-bottom">
                                <h4 className="price">
                                  {currentPost.price
                                    ? `GH₵ ${Number(currentPost.price).toFixed(2).toLocaleString()}`
                                    : ""}
                                </h4>
                                {/* <p>
                                  0 orders <span>-35%</span>
                                </p> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <Nodata />
                  )}
                  {/* {nodata && <Nodata/>} */}
                </div>
                <div className="shop-bottom-wrap">
                  {filteredProducts?.length > 0 ? (
                    <div className="shop-bottom-top">
                      {/* <h5 className="title">Shop</h5> */}
                      <p>
                        Showing {indexOfFirstPost + 1} to{" "}
                        {indexOfLastPost > filteredProducts?.length
                          ? filteredProducts?.length
                          : indexOfLastPost}{" "}
                        of {filteredProducts?.length} Item(s)
                      </p>
                      <Pagination
                        postsPerPage={postsPerPage}
                        totalPosts={filteredProducts.length}
                        paginate={paginate}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="shop-bottom-box">
                    <div className="shop-bottom-left">
                      <form action="#">
                        {/* <select id="short-By" name="select" className="form-select" aria-label="Default select example">
                                                <option value="">Show 09</option>
                                                <option>Show 12</option>
                                                <option>Show 08</option>
                                                <option>Show 06</option>
                                                <option>Show 03</option>
                                            </select> */}
                      </form>
                    </div>
                    <div className="shop-bottom-right">
                      <form action="#">
                        {/* <select id="short-by" name="select" className="form-select" aria-label="Default select example">
                                                <option value="">Default sorting</option>
                                                <option>Free Shipping</option>
                                                <option>Best Match</option>
                                                <option>Newest Item</option>
                                                <option>Size A - Z</option>
                                            </select> */}
                      </form>
                      <ul>
                        <li className="active">
                          <NavLink to="#">
                            <i className="fa-solid fa-table-cells"></i>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="#">
                            <i className="fa-solid fa-bars"></i>
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default RelatedProductsPage;

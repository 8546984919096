import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../config/connect";

const ProductColor = ({ initialValue, onSelected }) => {
  const [selectedColor, setSelectedColor] = useState(initialValue || null); // Initialize with initialValue prop
  const [variations, setVariations] = useState([]); // Store the list of colors
  const [loading, setLoading] = useState(true); // For loading state
  const { prodcode } = useParams(); // Extract product code from URL params

  // Fetch product color variations
  const getAllProductVariations = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `https://africamarkets.net/ghmarketconnect/?ghw=productservices&svg=fashionvariations&prcode=${prodcode}`
      );
      const json = await response.json();
      const firstResult = json["results"][0];

      if (firstResult && firstResult.color) {
        const colorObject = firstResult.color;
        if (colorObject.trim() !== "") {
          setVariations(colorObject.split(","));
        } else {
          setVariations([]);
        }
      }
    } catch (error) {
      console.error("Error fetching product variations:", error);
      setVariations([]);
    } finally {
      setLoading(false);
    }
  };

  // Fetch product color variations when the product code changes
  useEffect(() => {
    getAllProductVariations();
  }, [prodcode]);

  // Update state when initialValue changes
  useEffect(() => {
    if (initialValue) {
      setSelectedColor(initialValue);
    }
  }, [initialValue]);

  // Handle color selection toggle
  const handleColorClick = (color) => {
    const newSelectedColor = selectedColor === color ? null : color;
    setSelectedColor(newSelectedColor);
    onSelected(newSelectedColor); // Notify parent component
  };

  return (
    <div
      className="sizes-container"
      style={{ display: "flex", flexDirection: "row" }}
    >
      <span
        style={{
          fontWeight: "bold",
          color: "black",
          marginRight: "8px",
          alignSelf: "center",
        }}
      >
        Color:
      </span>
      {loading ? (
        <span>Loading colors...</span>
      ) : variations.length > 0 ? (
        <div className="sizes" style={{ display: "flex", gap: "8px" }}>
          {variations.map((color, index) => (
            <div
              key={index}
              className={`size ${selectedColor === color ? "selected" : ""}`}
              style={{
                padding: "8px 12px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                cursor: "pointer",
                backgroundColor:
                  selectedColor === color ? "#FF4800" : "#f9f9f9",
                color: selectedColor === color ? "#fff" : "#000",
              }}
              onClick={() => handleColorClick(color)}
            >
              {color}
            </div>
          ))}
        </div>
      ) : (
        <span>No colors available</span>
      )}
    </div>
  );
};

export default ProductColor;

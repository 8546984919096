import React from "react";
import { NavLink } from "react-router-dom";
import TrendingProdSkeleton from "./TrendingProdSkeleton";
import useFetch from "../hooks/useFetch";
import { API_BASE_URL } from "../config/connect";
// import { useProductData } from "./ProductsContextApi";

const Trendingproducts = ({ children }) => {
  // const {data: trendingProducts, loading} = useFetch(`https://africamarkets.net/ghmarketconnect/?ghw=productservices&svg=alltrendingprods`, {}, [], "trendingprods")
  const { data: trendingProducts, loading } = useFetch(
    `https://africamarkets.net/ghmarketconnect/?ghw=productservices&svg=alltrendingprods`,
    {},
    [],
    ""
  );
  // const allprods = useProductData().allProductData;

  // const trendingProducts = allprods
  //   ?.sort((a, b) => b.wishlistcount - a.wishlistcount)
  //   ?.slice(0, 6) || []

  return (
    <div className="trending-product">
      <h4 className="title">{children}</h4>
      <ul>
        {trendingProducts?.length > 0 ? (
          trendingProducts?.map((trendingProduct) => {
            return (
              <li
                className="trending-product-item mb-30"
                key={trendingProduct.id}
              >
                <div className="thumb">
                  <NavLink to={`/shopdetail/${trendingProduct.code}`}>
                    <img
                      src={
                        trendingProduct.previewpicture ||
                        trendingProduct.picture
                      }
                      alt=""
                    />
                  </NavLink>
                </div>
                <div className="content">
                  <h6 className="title">
                    <NavLink to={`/shopdetail/${trendingProduct.code}`}>
                      {trendingProduct.name}
                    </NavLink>
                  </h6>
                  <h4 className="price">
                    {trendingProduct.price
                      ? ` GH₵${Number(trendingProduct.price).toFixed(2)}`
                      : "-"}
                    {/* <del>₵29.08</del> */}
                  </h4>
                  <div className="content-bottom">
                    <ul>
                      {/* <li>1k+ Orders ~</li> */}
                      <li>
                        <i className="fa-solid fa-star"></i>
                        {Math.floor(trendingProduct.ratingaverage)}
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            );
          })
        ) : (
          <TrendingProdSkeleton />
        )}
      </ul>
    </div>
  );
};

export default Trendingproducts;

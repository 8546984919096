import { createSlice } from "@reduxjs/toolkit";

const cartItems = JSON.parse(localStorage.getItem("cart"));
const def_state = cartItems
  ? cartItems
  : {
      products: [],
      productsNumber: 0,
      productsAmt: 0,
    };

const initialState = def_state;

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    add(state, action) {
      // check if product exist in array
      const addProductExisits = state.products.find(
        (product) => product.prodcode === action.payload.prodcode
      );
      let total = 0;
      // console.log(addProductExisits.products)
      if (addProductExisits) {
        addProductExisits.quantity =
          addProductExisits.quantity + action.payload.quantity;
        total = addProductExisits.quantity * addProductExisits.price;
      } else {
        total = action.payload.quantity * action.payload.price;
        state.products.push({
          ...action.payload,
          quantity: action.payload.quantity,
          total,
          buyNow: false,
        });
      }

      //console.log(`total before total: ${total}`);
      state.productsNumber = state.products.reduce(
        (acc, curr) => acc + curr.quantity,
        0
      );
      state.productsAmt += total;

      localStorage.setItem("cart", JSON.stringify(state));
      // console.log(addProductExisits.quantity)
      // console.log(state.cart);
      //   console.log(action);
    },
    buy(state, action) {
      let total = 0;
      total = action.payload.quantity * action.payload.price;
      state.products.push({
        ...action.payload,
        quantity: action.payload.quantity,
        total,
        buyNow: true,
      });

      state.productsNumber = state.products.reduce(
        (acc, curr) => acc + curr.quantity,
        0
      );
      state.productsAmt += total;
      //   localStorage.setItem("cart", JSON.stringify(state));
    },
    remove(state, action) {
      // Find the product to remove
      const productToRemove = state.products.find(
        (product) => product.prodcode === action.payload
      );

      // If the product is found
      if (productToRemove) {
        // Decrease the total number of products in the cart by the quantity of the product removed
        state.productsNumber -= productToRemove.quantity;

        // Remove the product from the cart
        const index = state.products.findIndex(
          (product) => product.prodcode === action.payload
        );
        state.products.splice(index, 1);

        // Decrease the total amount in the cart by the product's price multiplied by its quantity
        state.productsAmt -= productToRemove.quantity * productToRemove.price;

        localStorage.setItem("cart", JSON.stringify(state));
      }
    },

    // remove(state, action) {
    //     // check if the product exist
    //     const productToRemove = state.products.find(
    //         (product) => product.prodcode === action.payload
    //     );
    //     // remove the quantity from product number
    //     // state.productsNumber = state.productsNumber - productToRemove.quantity;
    //     const prodNumber = state.products.reduce((acc, curr) => acc + curr.quantity, 0);

    //     state.productsNumber = state.productsNumber - prodNumber;
    //     // find index of the product removing
    //     const index = state.products.findIndex(
    //         (product) => product.prodcode === action.payload
    //     );
    //     // remove product from array
    //     state.products.splice(index, 1);

    //     state.productsAmt =
    //         state.productsAmt - productToRemove.quantity * productToRemove.price;

    //     localStorage.setItem("cart", JSON.stringify(state));
    //     // return state.filter((item) => item.prodcode !== action.payload);
    // },

    incrementInCart: (state, action) => {
      // console.log("itemInc: ", cartItems);
      const itemInc = state.products.find(
        (item) => item.prodcode === action.payload
      );
      if (itemInc.quantity >= 1) {
        itemInc.quantity = itemInc.quantity + 1;
        itemInc.total = itemInc.total + action.payload.price;
      }
      state.productsNumber = state.productsNumber + 1;
      state.productsAmt = state.productsAmt + itemInc.price;

      localStorage.setItem("cart", JSON.stringify(state));
    },

    decrementInCart: (state, action) => {
      const itemDec = state.products.find(
        (item) => item.prodcode === action.payload
      );

      if (itemDec.quantity <= 1) {
        // Quantity cannot be negative, so do nothing
        return;
      }

      // Decrement quantity and update total
      itemDec.quantity -= 1;
      itemDec.total -= itemDec.price;

      // Update total number of products and total amount in cart
      state.productsNumber -= 1;
      state.productsAmt -= itemDec.price;

      localStorage.setItem("cart", JSON.stringify(state));
    },

    emptyCart: (state, action) => {
      state.products = [];
      state.productsNumber = 0;
      state.productsAmt = 0;

      //console.log("state...ing...")
      //console.log(state)
      localStorage.setItem("cart", JSON.stringify(state));
    },
    emptyCartBuNow: (state, action) => {
      state.products = state.products.filter(
        (item) => !action.payload.includes(item)
      );
      state.productsNumber = 0;
      state.productsAmt = 0;

      localStorage.setItem("cart", JSON.stringify(state)); // Update local storage
    },
  },
});

export const {
  add,
  buy,
  remove,
  incrementInCart,
  decrementInCart,
  emptyCart,
  emptyCartBuNow,
} = cartSlice.actions;
export default cartSlice.reducer;
